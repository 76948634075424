(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editeventnew.controller:EditeventnewCtrl
   *
   * @description
   *
   */
  angular
    .module('editeventnew')
    .controller('EditeventnewCtrl', EditeventnewCtrl);

  function EditeventnewCtrl($rootScope, $state, $stateParams, Api, Upload, baseurl, baseendpoint, $timeout, $modal, $localStorage, $log, Auth, Cloudservices) {
    var vm = this;

    vm.noend = false;

    vm.splashPageTypes = [];
    vm.categories = [];
    vm.zones = [];
    vm.splashType = 0;
    vm.spVideo = false;
    vm.spSocial = false;
    vm.spAmount = false;
    vm.spEmail = false;
    vm.spLink = false;
    vm.spRegister = false;
    vm.networkEditEnabled = false;
    vm.hasTwilio = false;

    // Important!!! remove this sesction once magnet county feature is complete

    vm.isMagnet = false;
    var magnetID = 9104885; //add real magnetID

    if (Auth.getAuthData().operator.organisation === magnetID) {
      vm.isMagnet = true;
    }

    Cloudservices.getCloudServiceByName($stateParams.venueid, 'twilio').then(function (resp) {
      if(resp.data.rowCount >= 1){
        vm.hasTwilio = true;
      }
    }, function (err) {
      console.log(err);
    });

    Api.getNetworkTypes($stateParams.venueid).then(function (response) {
      vm.networkTypes = response.data.networkTypes;
      console.log(response);
    }, function (err) {
      $log.info(err);
    });

    vm.setNetworkType = function (typeid) {
      vm.event.networktype = typeid;
      vm.categories = [];
      Api.getNetworkType(typeid, $stateParams.venueid).then(function (response) {
        $log.info(response);
        if (response.data.networkType.networkStrategies.length > 0) {
          console.log(response.data.networkType.networkStrategies.length);
          for (var i = 0; i < response.data.networkType.networkStrategies.length; i++) {
            vm.categories.push(response.data.networkType.networkStrategies[i].id);
          }
        }
      }, function (err) {
        $log.info(err);
      });
    };

    //get splash page types
    function getSplashPageTypes() {
      Api.getSplashPageTypes(vm.provider).success(function (data) {
        vm.splashPageTypes = data.splashTypes;
        getSocialLogins();
        getCategories();
        $log.info(vm.splashPageTypes);
        vm.setSplashPageType(vm.splashType, true);
      }).error(function () {
        $log.info('get splash page types error');
      });
    }
    vm.anonymousLogin = {};
    function getSocialLogins() {
      Api.getSocialLogins(vm.provider).success(function (data) {
        for (var i = 0; i < data.socialLoginTypes.length; i++) {
          if(data.socialLoginTypes[i].name.toLowerCase() === 'anonymous') {
            vm.anonymousLogin = data.socialLoginTypes[i];
            vm.anonymousLogin.on = false;
            for (var j = 0; j < vm.openAuthList.length; j++) {
              if (vm.openAuthList[j] === data.socialLoginTypes[i].id) {
                vm.openAuthList.splice(j, 1);
                vm.anonymousLogin.on = true;
              }
            }
            data.socialLoginTypes.splice(i, 1);
          }
        }
        vm.socialLogins = data.socialLoginTypes;
        $log.info('social');
        $log.info(vm.socialLogins);
      }).error(function () {
        $log.info('get social error');
      });
    }

    // categories setup
    function getCategories() {
      Api.getCategories(vm.provider).success(function (data) {
        vm.optCatList = data.categories;
        $log.info(vm.optCatList);
      }).error(function () {
        $log.info('get categories error');
      });
    }
    // end categories setup
    //set Splash Page Type
    vm.setSplashPageType = function (type, set) {
      console.log(type);
      console.log(set);
      vm.spVideo = false;
      vm.spSocial = false;
      vm.spAmount = false;
      vm.spEmail = false;
      vm.spLink = false;
      vm.spRegister = false;
      var name = type.name;
      switch (name) {
      case 'Video':
        vm.spVideo = true;
        break;
      case 'Social':
        vm.spSocial = true;
        break;
      case 'Email':
        vm.spEmail = true;
        break;
      case 'Link':
        vm.spLink = true;
        break;
      case 'Payment':
        vm.spAmount = true;
        break;
      case 'Register':
        vm.spRegister = true;
        break;
      }
      if (set) {
        for (var splash in vm.splashPageTypes) {
          if (vm.splashPageTypes[splash].id === type.id) {
            vm.splashType = vm.splashPageTypes[splash];
          }
        }
        console.log(vm.splashType);
      }
    };

    vm.ctrlName = 'EditeventnewCtrl';
    // redirect to appropriate start page
    if ($state.current.name === 'admin.editevent') {
      $state.go('admin.editevent.editsetup');
    }

    // handles navigation away from incomplete form
    vm.allowStateChange = false;
    $rootScope.$on('$stateChangeStart',
      function (event, toState, toParams, fromState) {
        console.log(toState);
        console.log(vm.allowStateChange);
        if (vm.allowStateChange === true) {
          return;
        }
        if (fromState.name === 'admin.editevent.confirm') {
          if (toState.name === 'admin.editevent.editsetup' || toState.name === 'admin.editevent.editnetwork' || toState.name === 'admin.editevent.editsplash') {
            vm.allowStateChange = true;
            $state.go(toState.name);
          }
        }
        if (fromState.name === 'admin.editevent.editsetup' || fromState.name === 'admin.editevent.editnetwork' || fromState.name === 'admin.editevent.editsplash' || fromState.name === 'admin.editevent.confirm') {
          if (vm.allowStateChange === false) {
            console.log('called');
            event.preventDefault();
            var cancelEditModalInstance = $modal.open({
              templateUrl: 'editevent/views/canceleditevent.tpl.html',
              controller: 'CancelediteventCtrl as cancelEditModal'
            });

            cancelEditModalInstance.result.then(function () {
              vm.allowStateChange = true;
              $state.go(toState.name);
            }, function () {

            });
          } else {
            vm.allowStateChange = false;
            return;
          }
        } else {
          vm.allowStateChange = false;
          return;
        }
        vm.allowStateChange = false;
      });
    //end state changes

    vm.storage = $localStorage;

    // // manage social media

    vm.openAuthList = [];

    vm.addOpenAuth = function (value) {
      if (vm.openAuthList.indexOf(value) > -1) {
        vm.openAuthList.splice(vm.openAuthList.indexOf(value), 1);
      } else {
        vm.openAuthList.push(value);
      }
    };

    vm.priceSlider = {
      value: 2,
      options: {
        floor: 0,
        ceil: 50,
        translate: function (value) {
          if (value === 0) {
            return 'unlimited';
          }
          return value + ' MB';
        }
      }
    };

    // // manage steps

    vm.nextStep = function (data, state) {
      if ($state.current.name === 'admin.editevent.editsetup') {
        var timeError = false,
          now = new Date(),
          startTime = new Date(vm.event.startDate),
          endTime = new Date(vm.event.endDate),
          hour = 3600000,
          modalInstance;
        vm.stepOne = true;
        vm.stTimeInPast = false;
        vm.endTimeInPast = false;
        vm.endBeforeStart = false;
        vm.dateError = false;

        if (!vm.event.starttime || !vm.event.endtime) {
          timeError = true;
          vm.dateError = true;
        }

        if (endTime.getTime() < now.getTime()) {
          timeError = true;
          vm.endTimeInPast = true;
        }

        if (endTime.getTime() < startTime.getTime()) {
          timeError = true;
          vm.endBeforeStart = true;
        }

        if (!data.setUp.required && vm.eventImageAdded && !timeError) {
          if (endTime.getTime() - startTime.getTime() < hour) {
            modalInstance = $modal.open({
              templateUrl: 'eventcreation/views/eventtimemodal.tpl.html',
              controller: 'CancelmodalCtrl as cancelModal'
            });

            modalInstance.result.then(function () {
              vm.setupPassed = true;
              $state.go(state);
            }, function () {
              console.log('canceled');
            });
          } else {
            vm.allowStateChange = true;
            vm.setupPassed = true;
            $state.go(state);
          }
        }
      }

      if ($state.current.name === 'admin.editevent.editnetwork') {
        vm.stepTwo = true;
        if (!data.network.required && !data.network.pattern && vm.zones.length >= 1) {
          vm.netPassed = true;
          vm.allowStateChange = true;
          $state.go(state);
        }
      }

      if ($state.current.name === 'admin.editevent.editsplash') {
        vm.stepThree = true;
        var thisSplashType = vm.splashType.name;
        console.log(thisSplashType);
        if (!data.splash.required && vm.bckgrndImgAdded && vm.splashType.id > 0) {
          // console.log(vm.newEvent.videoUrl);
          // console.log(vm.splashType.length);

          if (thisSplashType === 'Social' && vm.openAuthList.length >= 1) {
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else if (thisSplashType === 'Video' && vm.newEvent.splashDetails.videoUrl.length > 0) {
            console.log('called');
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else if (thisSplashType === 'Payment' && vm.newEvent.splashDetails.amount > 0 && vm.newEvent.splashDetails.amount > 0) {
            console.log('called');
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else {
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          }
        }
      }
      vm.allowStateChange = false;
    };

    // // manage event date/time

    vm.minDate = new Date();

    vm.dateOptions = {
      formatYear: 'yy',
      startingDay: 0
    };

    vm.dateFormat = 'dd-MMMM-yyyy';
    vm.hstep = 1;
    vm.mstep = 1;

    vm.status = {
      openedSt: false,
      openedEnd: false
    };

    vm.openCalSt = function () {
      vm.status.openedSt = true;
    };

    vm.openCalEnd = function () {
      vm.status.openedEnd = true;
    };

    //get images
    Api.getImages().success(function (data) {
      //multiple image lists required
      vm.eventImageList = data.images;
      for (var i = 0; i < vm.eventImageList.length; i++) {
        vm.eventImageList[i].checked = false;
      }
      vm.venueImageList = angular.copy(vm.eventImageList);
      vm.backgroundImageList = angular.copy(vm.eventImageList);
      $log.info(vm.eventImageList);
      $log.info(vm.venueImageList);
      $log.info(vm.backgroundImageList);

    });

    vm.uploadEventImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.eventImageAdded = true;
        vm.eventImage = image.imageUri;
        vm.eventImageID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectEventImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.eventImageAdded = true;
        vm.eventImage = thisImage.imageuri;
        vm.eventImageID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    vm.uploadLogoImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.venueLogoAdded = true;
        vm.venueLogo = image.imageUri;
        vm.venueLogoID = image.id;
        console.log('success');
        vm.updateSplash();
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectLogoImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.venueLogoAdded = true;
        vm.venueLogo = thisImage.imageuri;
        vm.venueLogoID = thisImage.id;
        vm.updateSplash();
      }, function () {
        console.log('canceled');
      });
    };

    vm.uploadBckgrndImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.bckgrndImgAdded = true;
        vm.bckgrndImg = image.imageUri;
        vm.bckgrndImgID = image.id;
        console.log('success');
        vm.updateSplash();
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectBckgrndImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.bckgrndImgAdded = true;
        vm.bckgrndImg = thisImage.imageuri;
        vm.bckgrndImgID = thisImage.id;
        vm.updateSplash();
      }, function () {
        console.log('canceled');
      });
    };

    //modify event details:
    vm.updateSummary = function () {
      var modalParams = {
        name: vm.event.name,
        description: vm.event.description,
        starttime: vm.event.starttime,
        endtime: vm.event.endtime,
        redirectionurl: vm.event.redirectionurl
      };
      var modalInstance = $modal.open({
        templateUrl: 'editeventnew/views/editeventsummary.tpl.html',
        controller: 'EditeventsummaryCtrl as editsummary',
        // size: 'lg',
        resolve: {
          params: function () {
            return modalParams;
          }
        }
      });

      modalInstance.result.then(function (data) {
        vm.event.name = data.name;
        vm.event.description = data.description;
        vm.event.starttime = data.starttime;
        vm.event.endtime = data.endtime;
        vm.event.redirectionurl = data.redirectionurl;
        vm.updateEvent();
      }, function (err) {
        console.log(err);
      });
    };

    vm.updateNetwork = function () {
      var modalParams = {
        ssidname: vm.event.ssidname,
        networkType: vm.event.networkType,
        categories: vm.categories,
        venueid: $stateParams.venueid,
        zones: vm.zones
      };
      var modalInstance = $modal.open({
        templateUrl: 'editeventnew/views/eventnetwork.tpl.html',
        controller: 'EventnetworkmodalCtrl as eventnetworkmodal',
        // size: 'lg',
        resolve: {
          params: function () {
            return modalParams;
          }
        }
      });

      modalInstance.result.then(function (data) {
        vm.event.ssidname = data.ssidname;
        vm.event.networkType = data.networkType;
        vm.event.categories = data.categories;
        vm.event.venue = data.venueid;
        vm.zones = data.zones;
        vm.updateEvent();
      }, function (err) {
        console.log(err);
      });
    };

    vm.updateSplash = function () {
      var modalParams = {
        splashType: vm.splashType,
        splashPageTypes: vm.splashPageTypes,
        bckgrndImgAdded: vm.bckgrndImgAdded,
        bckgrndImg: vm.bckgrndImg,
        bckgrndImgID: vm.bckgrndImgID,
        venueLogoAdded: vm.venueLogoAdded,
        venueLogo: vm.venueLogo,
        venueLogoID: vm.venueLogoID,
        networkType: vm.event.networkType,
        venueid: $stateParams.venueid,
        socialLogins: vm.socialLogins,
        openAuthList: vm.openAuthList,
        redirectionurl: vm.event.redirectionurl,
        splashDetails: vm.event.splashdetails,
        selectBckgrndImage: false,
        uploadBckgrndImage: false,
        selectLogoImage: false,
        uploadLogoImage: false,
        updateSplash: false,
        isMagnet: vm.isMagnet,
        hasTwilio: vm.hasTwilio,
        anonymousLogin: vm.anonymousLogin
      };
      console.log(modalParams);
      var modalInstance = $modal.open({
        templateUrl: 'editeventnew/views/eventsplash.tpl.html',
        controller: 'EventsplashmodalCtrl as eventsplashmodal',
        size: 'lg',
        resolve: {
          params: function () {
            return modalParams;
          }
        }
      });

      modalInstance.result.then(function (data) {
        vm.splashType = data.splashType;
        vm.splashPageTypes = data.splashPageTypes;
        vm.bckgrndImgAdded = data.bckgrndImgAdded;
        vm.bckgrndImg = data.bckgrndImg;
        vm.bckgrndImgID = data.bckgrndImgID;
        vm.venueLogoAdded = data.venueLogoAdded;
        vm.venueLogo = data.venueLogo;
        vm.venueLogoID = data.venueLogoID;
        vm.event.networkType = data.networkType;
        vm.socialLogins = data.socialLogins;
        vm.openAuthList = data.openAuthList;
        vm.event.redirectionurl = data.redirectionurl;
        vm.event.splashdetails = data.splashDetails;
        vm.anonymousLogin = data.anonymousLogin;
        if (data.selectBckgrndImage) {
          vm.selectBckgrndImage();
        } else if (data.uploadBckgrndImage) {
          vm.uploadBckgrndImage();
        } else if (data.selectLogoImage) {
          vm.selectLogoImage();
        } else if (data.uploadLogoImage) {
          vm.uploadLogoImage();
        } else if (data.updateSplash) {
          vm.updateEvent();
        }
        //  vm.updateEvent();
      }, function (err) {
        console.log(err);
      });
    };

    vm.endEvent = function () {
      var modalParams = {
        endtime: vm.event.endtime
      };
      var modalInstance = $modal.open({
        templateUrl: 'editeventnew/views/endevent.tpl.html',
        controller: 'EndeventmodalCtrl as endevent',
        // size: 'lg',
        resolve: {
          params: function () {
            return modalParams;
          }
        }
      });

      modalInstance.result.then(function (data) {
        vm.event.endtime = data.endtime;
        vm.updateEvent();
      }, function (err) {
        console.log(err);
      });
    };

    Api.getEvent($stateParams.eventid, $stateParams.venueid).success(function (data) {
      console.log('event data');
      console.log(data);
      vm.event = data.event;
      vm.zones = data.event.zones;
      vm.noend = (new Date(data.event.endtime).getTime() === 253402300740905) ? true: false;
      console.log('event: ', vm.event);
      // console.log(vm.zones);
      // console.log('slider value' + vm.event.trafficlimitsta / 1000);
      vm.netPassed = true;
      vm.setupPassed = true;
      vm.splashPassed = true;
      vm.getZones(vm.event.venue);
      vm.priceSlider.value = vm.event.trafficlimitsta / 1000;
      var thisSplash;
      if (vm.event.splashdetails) {
        thisSplash = vm.event.splashdetails;
        if (thisSplash.amount) {
          thisSplash.amount = thisSplash.amount / 100;
        }
        if (thisSplash.period) {
          thisSplash.period = thisSplash.period / 3600000;
        }
        if (vm.event.splashdetails.videoUrl) {
          vm.event.splashdetails.videoUrl = vm.event.splashdetails.videoUrl;
        }
      }
      var splashType = {};
      splashType.id = vm.event.splashtypeid;
      splashType.name = vm.event.splashtypename;
      vm.splashType = splashType;
      vm.event.networkType = vm.event.networktype;
      //vm.event.splashDetails.videoUrl = vm.event.splashdetails.videoURL;
      if (vm.event.eventimageurl.length > 0) {
        vm.eventImageAdded = true;
        vm.eventImageID = vm.event.eventimage;
        vm.eventImage = vm.event.eventimageurl;
      }
      if (vm.event.backgroundimage.length > 0) {
        vm.bckgrndImgAdded = true;
        vm.bckgrndImgID = vm.event.splashpagebackgroundimage;
        vm.bckgrndImg = vm.event.backgroundimage;
      }
      if (vm.event.logoimage.length > 0) {
        vm.venueLogoAdded = true;
        vm.venueLogoID = vm.event.splashpagelogoimage;
        vm.venueLogo = vm.event.logoimage;
      }
      for (var i = 0; i < vm.event.socialLoginTypes.length; i++) {
        if (vm.event.socialLoginTypes[i].id === vm.anonymousLogin.id) {
          vm.anonymousLogin.on = true;
        }
        vm.openAuthList.push(vm.event.socialLoginTypes[i].id);
      }
      for (var j = 0; j < vm.event.categories.length; j++) {
        vm.categories.push(vm.event.categories[j].id);
      }
      if (Auth.getAuthData()) {
        vm.event.org = Auth.getAuthData().operator.organisation;
      }
      Api.getInfrastructures(vm.event.venue).then(function (res) {
        var infra = res.data.infrastructures[0];
        vm.provider = JSON.parse(infra.provider);
        console.log(infra);
        getSplashPageTypes();
      }, function (err) {
        console.log(err);
      });
    });

    //accesspoint setup
    vm.getZones = function (id) {
      vm.zoneList = [];
      Api.getZones(id).success(function (data) {
        vm.zoneList = data.zones;
        var newList = [];
        for (var i = 0; i < vm.zoneList.length; i++) {
          for (var j = 0; j < vm.event.zones.length; j++) {
            if (vm.zoneList[i].id === vm.event.zones[j].zone) {
              newList.push(vm.zoneList[i]);
            }
          }
        }
        vm.event.zones = newList;
      }).error(function () {
        $log.info('get ap error');
      });
    };

    vm.cancel = function () {
      $state.go('admin.events');
    };

    vm.updateEvent = function () {
      var thisSplash = vm.event.splashdetails;
      if (thisSplash) {
        if (thisSplash.amount) {
          thisSplash.amount = thisSplash.amount * 100;
        }
        if (thisSplash.period) {
          thisSplash.period = thisSplash.period * 3600000;
        }
      }
      if (vm.anonymousLogin.on) {
        vm.addOpenAuth(vm.anonymousLogin.id);
      } else {
        for (var j = 0; j < vm.openAuthList.length; j++) {
          if (vm.openAuthList[j] === vm.anonymousLogin.id) {
            vm.openAuthList.splice(j, 1);
          }
        }
      }
      var thisBandwidth = vm.priceSlider.value * 1000,
        thisEvent = {
          orgId: vm.event.org,
          venueId: vm.event.venue,
          name: vm.event.name,
          description: vm.event.description,
          eventimage: vm.eventImageID,
          ssidname: vm.event.ssidname,
          starttime: vm.event.starttime,
          endtime: vm.event.endtime,
          zones: vm.zones,
          details: vm.event.details,
          categories: vm.categories,
          trafficlimitsta: thisBandwidth,
          splashpagebackgroundimage: vm.bckgrndImgID,
          splashpagelogoimage: vm.venueLogoID,
          sociallogintypes: vm.openAuthList,
          redirectionurl: vm.event.redirectionurl,
          eventstatus: vm.event.eventstatus,
          splashDetails: thisSplash,
          networkTypeId: vm.event.networkType,
          splashTypeId: vm.splashType.id,
          splashpage: vm.event.splashpage
        };

      if (vm.event.description === null) {
        thisEvent.description = '';
      }

      console.log('thisEvent !!!');
      console.log(thisEvent);

      Api.updateEvent(vm.event.id, vm.event.venue, thisEvent).success(function () {
        vm.storage.alerts.push({
          messageType: 'event',
          message: 'Event Update'
        });
        vm.allowStateChange = true;
        $state.go('admin.events');
      }).error(function (error) {
        console.log(error);
      });
    };
  }
}());
