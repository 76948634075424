(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.factory:ApiAnalytic
   *
   * @description
   *
   */
  angular
    .module('leoperations')
    .factory('ApiAnalytic', ApiAnalytic);

  function ApiAnalytic(baseurl, baseendpoint, analyticsbase, analyticsendpoint, $http, $base64, Auth) {
    var ApiAnalyticBase = {};
    ApiAnalyticBase.someValue = 'ApiAnalytic';
    //analytics Api
    ApiAnalyticBase.getClientsOnlineByVenue = function (venueid, provider) {
      var orgid = Auth.getAuthData().operator.organisation;
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/liveanalytic/venue/' + venueid + '/provider/' + provider + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getClientsOnlineByEvent = function (venueid, provider, eventid) {
      var orgid = Auth.getAuthData().operator.organisation;
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/liveanalytic/venue/' + venueid + '/provider/' + provider + '/event/' + eventid + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // Dwell Time
    ApiAnalyticBase.getDwellTimeByVenue = function (venueid, start, end) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(analyticsbase + analyticsendpoint + '/dwelltime/venue/' + venueid + '?org=' + orgid + '&start=' + start + '&end=' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getDwellTimeByEvent = function (venueid, eventid, start, end) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(analyticsbase + analyticsendpoint + '/dwelltime/venue/' + venueid + '/event/' + eventid + '?org=' + orgid + '&start=' + start + '&end=' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };

    //User Journey
    ApiAnalyticBase.getUserJourney = function (venueid, user, date) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/userjourney/venue/' + venueid + '/user/' + user + '?date=' + date, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // User Impressions
    ApiAnalyticBase.getImpressionsByVenue = function (venueid, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/performance/impressions/venue/' + venueid + '/start/' + start + '/end/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getImpressionsByEvent = function (eventid, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/performance/impressions/event/' + eventid + '/start/' + start + '/end/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // Footfall
    ApiAnalyticBase.getFootFallByVenue = function (venueid, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/footfall/venue/' + venueid + '/start/' + start + '/end/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getFootFallByEvent = function (eventid, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/footfall/event/' + eventid + '/start/' + start + '/end/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getLoginDetails = function (venueId, eventId) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/login/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getLoginDetailsByVenue = function (venueId) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/loginzone/' + venueId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getLoginDetailsByZone = function (venueId, zoneId, eventId) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/loginzone/' + zoneId + '/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getGenderDetails = function (venueId, eventId) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/gender/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getGenderDetailsByZone = function (venueId, zoneId, eventId) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/genderzone/' + zoneId + '/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getSocialLoginAnalyticsByZone = function (venueId, zoneId, eventId) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/loginzone/' + zoneId + '/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getValidatedEmailsByVenue = function (venueId) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/e-mails/' + venueId, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getValidatedEmailsByEvent = function (venueId, eventId) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/e-mails/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getOnlineUsers = function (eventId) {
      var token = Auth.getAuthData().token;
      return $http.get(baseurl + baseendpoint + '/enduser/currentusers/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getLoginByDay = function (eventId, venueId, start, end) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/' + eventId + '/userlogin/' + start + '/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getValidatedEmails = function (eventId, venueId) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/e-mails/' + eventId, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getLoginByDay = function (eventId, venueId, start, end) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/' + eventId + '/userlogin/' + start + '/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getMostFrequentVisitors = function (venueId, numUsers, starttime, endtime) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/mostfrequentvisitors/' + numUsers + '/' + starttime + '/' + endtime, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getMostFrequentVisitorsByEvent = function (eventId, numUsers, starttime, endtime) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + eventId + '/mostfrequentvisitorsbyevent/' + numUsers + '/' + starttime + '/' + endtime, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getUserProfile = function (venueId, userId) {
      var token = Auth.getAuthData().token;
      return $http.get(baseurl + baseendpoint + '/profile/venue/' + venueId + '/user/' + userId, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getAllNewReturningVisitors = function (venueId, starttime, endtime) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + venueId + '/allnewreturningvisitors/' + starttime + '/' + endtime, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getAllNewReturningVisitorsForEvents = function (eventId, starttime, endtime) {
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/enduser/' + orgid + '/' + eventId + '/allnewreturningvisitorsevents/' + starttime + '/' + endtime, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getUsersJourney = function (venueid, providerid, macAddress, date) {
      console.log('called api');
      var token = Auth.getAuthData().token,
        orgid = Auth.getAuthData().operator.organisation;
      return $http.get(analyticsbase + analyticsendpoint + '/usersjourney/venue/' + venueid + '/provider/' + providerid + '?MacAddress=' + macAddress + '&date=' + date + '&org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getLoginByVenue = function (venueid, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/performance/login/venue/' + venueid + '/start/' + start.getTime() + '/end/' + end.getTime(), {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getLoginByVenue2 = function (venueid, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/performance/login/venue/' + venueid + '/start/' + start + '/end/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getLoginByEvent = function (eventid, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/performance/login/event/' + eventid + '/start/' + start.getTime() + '/end/' + end.getTime(), {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getEmailCountyListEvent = function (venueID, eventID, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(baseurl + baseendpoint + '/enduser/county/venue/' + venueID + '/event/' + eventID + '/' + start + '/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getEmailCountyListVenue = function (venueID, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(baseurl + baseendpoint + '/enduser/county/venue/' + venueID + '/' + start + '/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getLoginAnalyticsVenue = function (venue, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/login/venue/' + venue + '/start/' + start + '/end/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getLoginAnalyticsEvent = function (venue, eventid, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/login/venue/' + venue + '/event/' + eventid + '/start/' + start + '/end/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getUserProfilesVenue = function (venue, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/customerprofiles/venue/' + venue + '/start/' + start + '/end/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getUserProfilesEvent = function (venue, eventid, start, end) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/customerprofiles/venue/' + venue + '/event/' + eventid + '/start/' + start + '/end/' + end, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getOnlineUsersVenue = function (venue, provider) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/customersonline/venue/' + venue + '/provider/' + provider, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getOnlineUsersEvent = function (venue, eventid, provider) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(analyticsbase + analyticsendpoint + '/customersonline/venue/' + venue + '/event/' + eventid + '/provider/' + provider + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.checkUserOnline = function (venue, provider, data) {
      var token = Auth.getAuthData().token;
      return $http.post(analyticsbase + analyticsendpoint + '/customeronline/venue/' + venue + '/provider/' + provider, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getApActivity = function (venue, date) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/apactivity/venue/' + venue + '/date/' + date, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getBusyAP = function (venue, date) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/busyap/venue/' + venue + '/date/' + date, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getJourney = function (venue, date) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/journey/venue/' + venue + '/date/' + date, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // Map Endpoints
    ApiAnalyticBase.createMap = function (map) {
      var token = Auth.getAuthData().token;
      return $http.post(analyticsbase + analyticsendpoint + '/map', map, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.getMapsByVenue = function (venue) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/map/venue/' + venue, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getMap = function (venue, id) {
      var token = Auth.getAuthData().token;
      return $http.get(analyticsbase + analyticsendpoint + '/map/venue/' + venue +'/map/'+id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.editMap = function (map, venue, id) {
      var token = Auth.getAuthData().token;
      return $http.put(analyticsbase + analyticsendpoint + '/map/venue/' + venue +'/map/'+id, map, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiAnalyticBase.deleteMap = function (venue, id) {
      var token = Auth.getAuthData().token;
      return $http.delete(analyticsbase + analyticsendpoint + '/map/venue/' + venue +'/map/'+id, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiAnalyticBase.getMapForDay = function (type, venue, date, macarray) {
      var token = Auth.getAuthData().token;
      return $http.post(analyticsbase + analyticsendpoint + '/map/'+type+'/venue/' + venue +'/date/'+date.getTime(), {macarray: macarray}, {
        headers: {
          'x-access-token': token
        }
      });
    };



    return ApiAnalyticBase;
  }
}());
