<div class="modal-header">
  <img class="modal-img" src="./images/connect2fi/connect2filogo_small.png">
</div>
<div class="modal-body">
  <h4>{{modalService.message.messageHeader}}</h4>
  <p>{{modalService.message.messageBody}}</p>
</div>
<div class="modal-footer">
  <div ng-show="modalService.message.cancel.length > 0">
    <button class="btn btn-danger" type="button" ng-click="modalService.ok()">{{modalService.message.ok}}</button>
    <button class="btn btn-warning" type="button" ng-click="modalService.cancel()">{{modalService.message.cancel}}</button>
  </div>
  <div ng-hide="modalService.message.cancel.length > 0">
    <button class="btn btn-primary btn-block" type="button" ng-click="modalService.ok()">{{modalService.message.ok}}</button>
  </div>
</div>