<div class="modal-header">
  <img class="modal-img" src="./images/connect2fi/connect2filogo_small.png">
</div>
<div class="modal-body">
  <div ng-show="createFromController.error" class="center-error">
    <h5>{{bulkVenueCreateModal.error}}</h5>
  </div>
  <div ng-hide="createFromController.error.length > 1">
    <h4 ng-hide="createFromController.infrastructure.provider === 1">Would you like to create from controller?</h4>
    <h4 ng-show="createFromController.infrastructure.provider === 1">Would you like to create from CSV File?</h4>
  </div>

  <div ng-if="createFromController.infrastructure.provider === 1">
    <input type="file" fileread="createFromController.csvfile" required>
  </div>

</div>
<div class="modal-footer">
  <button class="btn btn-danger" type="button" ng-click="createFromController.ok()">Yes</button>
  <button class="btn btn-info" type="button" ng-click="createFromController.no()" ng-hide="createFromController.error.length > 1">No</button>
  <button class="btn btn-warning" type="button" ng-click="createFromController.cancel()">Cancel</button>
</div>