(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.constant:adminbase
   *
   * @description
   *
   */
  angular
    .module('leoperations')
    .constant('analyticsbase', 'https://api.liquidedge.io/analytics-api');
}());
