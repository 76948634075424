<div class="panel panel-default">
  <div class="eventPadding">
    <div class="row">
      <div class="col-xs-9 col-sm-6 col-ms-6">
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Splash Page Type</h4>
          </div>
          <div class="panel-body">
            <select class="form-control" name="splashType" ng-model="eventsplashmodal.params.splashType" ng-options="type as type.name for type in eventsplashmodal.params.splashPageTypes" ng-change="eventsplashmodal.setSplashPageType(eventsplashmodal.params.splashType, false)">
            </select>
          </div>
          <div ng-show="eventsplashmodal.splashType.id <= 0">
            <p class="text-danger">
              <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
              <strong>
                Splash Type is required</strong>
            </p>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Background Image</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <div class="row">
                <div ng-show="eventsplashmodal.params.bckgrndImgAdded" class="col-md-4 col-md-offset-4">
                  <img ng-src="{{staticUrl}}{{eventsplashmodal.params.bckgrndImg}}" class="img-responsive" />
                </div>
              </div>
              <div class="row">
                <button class="btn btn-danger" ng-click="eventsplashmodal.selectBckgrndImage()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>
                <button class="btn btn-danger" ng-click="eventsplashmodal.uploadBckgrndImage()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>
              </div>
            </div>
            <div ng-show="!eventsplashmodal.params.bckgrndImgAdded">
              <p class="text-danger">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                <strong>
                  Background Image is required</strong>
              </p>
            </div>
          </div>
        </div>

        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Venue Logo</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <div class="row">
                <div ng-show="eventsplashmodal.params.venueLogoAdded" class="col-md-4 col-md-offset-4">
                  <img ng-src="{{staticUrl}}{{eventsplashmodal.params.venueLogo}}" class="img-responsive" />
                </div>
              </div>
              <div class="row">
                <button class="btn btn-danger" ng-click="eventsplashmodal.selectLogoImage()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>
                <button class="btn btn-danger" ng-click="eventsplashmodal.uploadLogoImage()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>
              </div>
            </div>
            <div ng-show="!eventsplashmodal.params.venueLogoAdded">
              <p class="text-danger">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                <strong>
                  Venue Logo is required</strong>
              </p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-show="eventsplashmodal.spSocial">
          <div class="panel-heading clearfix">
            <h4>Social Media Login</h4>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-xs-12">
                <div class="col-sm-3" ng-repeat="social in eventsplashmodal.params.socialLogins">
                  <button class="btn btn-social" style="background-image: url({{adminStaticUrl}}{{social.imageuri}});background-size: 100% 100%;" ng-click="eventsplashmodal.addOpenAuth(social.id)" ng-disabled="social.name ==='Twilio' && !eventsplashmodal.params.hasTwilio">
                    <div class="content">
                      <span class="badge badgeSocial" ng-show="eventsplashmodal.params.openAuthList.indexOf(social.id) > -1"><i class="fa fa-check-circle-o fa-2x"></i></span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div class="row" ng-show="eventsplashmodal.anonymousLogin.name === 'Anonymous'">
              <div class="col-xs-12">
                <h5><input type="checkbox" id="anonymousLogin" name="anonymousLogin" ng-model="eventsplashmodal.anonymousLogin.on" /> Allow anonymous signin.</h5>
              </div>
            </div>
            <div ng-show="eventsplashmodal.params.openAuthList.length < 1">
              <p class="text-danger">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                <strong>
                  Please select social log in</strong>
              </p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-show="eventsplashmodal.spVideo">
          <div class="panel-heading clearfix">
            <h4>Video Url</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error' : createEvent.videoUrl.$error}">
              <input type="text" class="form-control" id="videoUrl" ng-model="eventsplashmodal.params.splashDetails.videoUrl" name="videoUrl" placeholder="eg. http://mysite.com" required>
            </div>
            <div ng-messages="createEvent.videoUrl.$error" ng-show="createEvent.videoUrl.$error">
              <p class="text-danger" ng-message="required">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                <strong>
                  Video Url is required</strong>
              </p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-show="eventsplashmodal.spAmount">
          <div class="panel-heading clearfix">
            <h4>Amount</h4>
          </div>
          <div class="panel-body">
            <div class="input-group" ng-class="{ 'has-error' : createEvent.amount.$error}">
              <div class="input-group-addon">&euro;</div>
              <input type="number" min="0" step="1" class="form-control" id="amount" ng-model="eventsplashmodal.event.splashDetails.amount" name="amount" placeholder="0" required>
            </div>
            <div ng-messages="createEvent.amount.$error" ng-show="createEvent.amount.$error">
              <p class="text-danger" ng-message="required">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                <strong>
                  Amount is required</strong>
              </p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-show="eventsplashmodal.spAmount">
          <div class="panel-heading clearfix">
            <h4>Duration (hrs)</h4>
          </div>
          <div class="panel-body">
            <div class="input-group" ng-class="{ 'has-error' : createEvent.period.$error}">
              <input type="number" min="0" step="1" class="form-control" id="period" ng-model="eventsplashmodal.event.splashDetails.period" name="period" placeholder="0" required>
            </div>
            <div ng-messages="createEvent.amount.$error" ng-show="createEvent.period.$error">
              <p class="text-danger" ng-message="required">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                <strong>
                  Duration is required</strong>
              </p>
            </div>
          </div>
        </div>
  <div class="panel panel-default panel-form" ng-show="eventsplashmodal.spSocial">
    <div class="panel-heading clearfix">
      <h4>Social Media Settings</h4>
    </div>
    <div class="panel-body">
      <div class="form-group" ng-class="{ 'has-error' : eventcreation.stepThree}">
        <h5><input type="checkbox" id="addlikebutton" name="addlikebutton" ng-model="eventsplashmodal.addlikebutton"/> Facebook Like Button </h5>
        <div ng-show="eventsplashmodal.addlikebutton">
          <div class="row" style="border-bottom: none; padding: 5px" ng-show="eventsplashmodal.venue.customFbLikeUrl">
            <div class="col-xs-12">
              <div style="margin-top: 5px"><input type="checkbox" id="defaultFbLikeUrl" name="defaultFbLikeUrl" ng-model="eventsplashmodal.defaultFbLikeUrl" ng-change="eventsplashmodal.customFbLike = false;"/> Default - {{eventsplashmodal.venue.customFbLikeUrl}}</div>
            </div>
          </div>
          <div class="row" style="border-bottom: none; padding: 5px">
            <div class="col-xs-4">
              <div style="margin-top: 5px"><input type="checkbox" id="customFbLike" name="customFbLike" ng-model="eventsplashmodal.customFbLike" ng-change="eventsplashmodal.defaultFbLikeUrl = false;"/> Custom.</div>
            </div>
            <div class="col-xs-8" ng-hide="eventsplashmodal.defaultFbLikeUrl">
              <input type="url" class="form-control pull-right" id="customFbLikeUrl" ng-model="eventsplashmodal.params.splashDetails.customFbLikeUrl" name="customFbLikeUrl" placeholder="Custom Facebook Page Url" ng-change="eventsplashmodal.defaultFbLikeUrl = false;">
            </div>
          </div>
          <div class="row" style="border-bottom: none; padding: 5px">
            <div class="col-xs-offset-6 col-xs-6">
              <div ng-show="!createEvent.customFbLikeUrl.$valid && eventcreation.stepThree" class="pull-right">
                <p class="text-danger">
                  <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                  <strong> Custom Url must be a valid url. Example https://www.facebook.com/Liquidedge/</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
        <!-- <div class="panel panel-default panel-form" ng-show="eventsplashmodal.isMagnet">
          <div class="panel-heading clearfix">
            <h4>County Dropdown</h4>
          </div>
          <div class="panel-body">
            <label>
            <input type="checkbox" ng-model="eventsplashmodal.params.splashDetails.countyDropDown"
               ng-true-value="true" ng-false-value="false" ng-change="checkChanged()">
               Include County Dropdown
            </label>
          </div>
        </div> -->
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Redirection Url</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error' : eventsplashmodal.params.redirectionurl.$error}">
              <input type="text" class="form-control" id="redirectionURL" ng-model="eventsplashmodal.params.redirectionurl" name="redirection" placeholder="eg. http://mysite.com" required>
            </div>
            <div ng-messages="eventsplashmodal.params.redirectionurl.$error" ng-show="eventsplashmodal.params.redirectionurl.$error">
              <p class="text-danger" ng-message="required">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                <strong>
                  Redirection Url is required</strong>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-9 col-sm-6 col-md-5 col-md-offset-1">
        <h3 class="text-center">Splash Page</h3>
        <div class="col-md-10 col-md-offset-1">
          <div class="splashBackground" ng-style="{ 'background-image': 'url(' + staticUrl + eventsplashmodal.params.bckgrndImg + ')' }">
            <div class="content">
              <div class="row venueLogo">
                <div class="col-xs-8 col-xs-offset-2" ng-show="eventsplashmodal.params.venueLogoAdded">
                  <img ng-src="{{staticUrl}}{{eventsplashmodal.params.venueLogo}}" class="img-responsive" />
                </div>
              </div>
              <div class="row login-mod well well-sm" ng-show="eventsplashmodal.params.bckgrndImgAdded && eventsplashmodal.spEmail">
                <div class="scaled">
                  <p class="railtext">
                    Connect to our FREE WIFI with
                  </p>
                  <form class="loginForm" name="loginForm" ng-submit="" role="form" novalidate>
                    <div class="form-group" ng-class="{ 'has-error' : loginForm.email.$error && authentication.formSubmitted }">
                      <input type="email" name="email" class="form-control" placeholder="Email">
                    </div>
                    <div class="form-group railtext">
                      <input type="checkbox" ng-model="authentication.terms" required name="terms">
                      <a ng-click="">Terms and Conditions</a>
                    </div>
                    <div class="row text-center">
                      <div class="col-md-12 col-xs-12 col-sm-12">
                        <button type="submit" class="btn btn-danger">
                          CONNECT
                        </button>
                      </div>
                    </div>
                  </form>
                  <div class="leLogo">
                    <img class="img-responsive" src="./images/connect2fi/connect2filogo_brand.png" alt="Liquidedge">
                  </div>
                </div>
              </div>
              <div class="row login-mod well well-sm" ng-show="eventsplashmodal.params.bckgrndImgAdded && eventsplashmodal.spRegister">
                <div class="scaled">
                  <p class="railtext">
                    Connect to our FREE WIFI with
                  </p>
                  <form class="loginForm" name="loginForm" ng-submit="" role="form" novalidate>
                    <div class="form-group" ng-class="{ 'has-error' : loginForm.email.$error && authentication.formSubmitted }">
                      <input type="email" name="email" class="form-control" placeholder="Email">
                    </div>
                    <div class="form-group" ng-class="{ 'has-error' : loginForm.email.$error && authentication.formSubmitted }">
                      <input type="password" name="password" class="form-control" placeholder="Password">
                    </div>
                    <div class="form-group railtext">
                      <input type="checkbox" ng-model="authentication.terms" required name="terms">
                      <a ng-click="">Terms and Conditions</a>
                    </div>
                    <div class="row text-center">
                      <div class="col-md-12 col-xs-12 col-sm-12">
                        <button type="submit" class="btn btn-danger">
                          CONNECT
                        </button>
                      </div>
                    </div>
                  </form>
                  <div class="leLogo">
                    <img class="img-responsive" src="./images/connect2fi/connect2filogo_brand.png" alt="Liquidedge">
                  </div>
                </div>
              </div>
              <div class="row video-mod" ng-show="eventsplashmodal.params.bckgrndImgAdded && eventsplashmodal.spVideo">
                <div class="scaled">
                  <div class="leLogo">
                    <img class="img-responsive" src="./images/youtube.jpg" alt="Youtube Video">
                  </div>
                </div>
              </div>
              <div class="footer-mod" ng-show="eventsplashmodal.params.bckgrndImgAdded && eventsplashmodal.spVideo">
                <div class="centered">
                  <strong class="railtext">
                    Connect to our FREE WIFI with
                  </strong>
                  <div class="btn-deck">
                    <button type="submit" class="btn btn-danger btn-xs" ng-click="">
                      More Info
                    </button>
                    <button type="submit" class="btn btn-info btn-xs" ng-click="">
                      No, Thanks
                    </button>
                  </div>
                  <div class="leLogo">
                    <img class="img-responsive" src="./images/connect2fi/connect2filogo_brand.png" alt="Liquidedge">
                  </div>
                </div>
              </div>
              <div class="footer-mod" ng-show="eventsplashmodal.params.bckgrndImgAdded && eventsplashmodal.spLink">
                <div class="centered">
                  <strong class="railtext">
                    Connect to our FREE WIFI with
                  </strong>
                  </br>
                  <button type="submit" class="btn btn-danger btn-deck btn-xs" ng-click="">
                    CONNECT
                  </button>
                  <div class="leLogo">
                    <img class="img-responsive" src="./images/connect2fi/connect2filogo_brand.png" alt="Liquidedge">
                  </div>
                </div>
              </div>
              <div class="footer-mod" ng-show="eventsplashmodal.params.bckgrndImgAdded && eventsplashmodal.spSocial">
                <div class="socialCentred">
                  <div class="DocumentList">
                    <strong class="railtext">
                      Connect to our FREE WIFI with
                    </strong>
                    <div class="row">
                      <div class="socIconsList" ng-repeat="social in eventsplashmodal.params.socialLogins" ng-show="eventsplashmodal.params.openAuthList.indexOf(social.id) > -1">
                        <button class="btn btn-social" style="background-image: url({{adminStaticUrl}}{{social.imageuri}});background-size: 100% 100%;">
                          <div class="content">
                          </div>
                        </button>
                      </div>
                    </div>
                    <div class="row" ng-show="eventsplashmodal.anonymousLogin.on">
                      <a>Continue Without Login</a> <br />
                      <br />
                    </div>
                    <div class="" style="margin-left: auto;margin-right: auto;  display: block;width: 10vw;height: auto;">
                      <img class="img-responsive" src="./images/connect2fi/connect2filogo_brand.png" alt="Liquidedge">
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-footer">
      <div class="pull-right">
        <button class="btn btn-default" ng-click="eventsplashmodal.cancel()">Cancel</button>
        <button class="btn btn-default btn-danger" ng-click="eventsplashmodal.ok()">Submit</button>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>
