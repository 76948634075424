(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name venues.controller:DeletevenuemodalCtrl
   *
   * @description
   *
   */
  angular
    .module('venues')
    .controller('CreateFromControllerCtrl', CreateFromControllerCtrl);

  function CreateFromControllerCtrl($modalInstance, $log, Api, infrastructure) {
    var vm = this;
    vm.ctrlName = 'CreateFromControllerCtrl';
    vm.selected = '';
    vm.infrastructure = infrastructure;
    vm.disabled = true;
    vm.error = '';

    vm.readCsv = function(file, callback) {
      var lines = file.split('\n');
      var first = true;
      var mapValues = [];
      var aps = [];
      lines.forEach(function (ap) {
        var apVar = ap.split(',');
        if(first){
          apVar.forEach(function(plotMap) {
            mapValues.push(plotMap);
          });
          first = false;
        } else {
          var length = apVar.length;
          var index = 0;
          var ap = {};
          for(index; index<length; index++) {
            ap[mapValues[index]] = apVar[index].replace(/\"/g, '');
          }
          aps.push(ap);
        }
      });
      callback(aps);
    };

    vm.ok = function () {
      if (vm.csvfile) {
        vm.readCsv(vm.csvfile, function(result){
          var mappedAps = result.map(function(ap) {
            return {
              mac:ap['IAP MAC'],
              name:ap.Hostname,
              type:"uap",
              wlangroup:"default"
            }
          });
          var data = {
            choice: true,
            aps: mappedAps,
            infrastructure: vm.infrastructure
          };
         $modalInstance.close(data);
        });
      } else {
        var data = {
          choice: true,
          infrastructure: vm.infrastructure
        };
       $modalInstance.close(data);
      }
    };

    vm.no = function () {
      var data = {
        choice: false
      };
      $modalInstance.close(data);
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };

  }
}());
angular.module('venues').directive("fileread", [function () {
    return {
        scope: {
            fileread: "="
        },
        link: function (scope, element, attributes) {
            element.bind("change", function (changeEvent) {
                var reader = new FileReader();
                reader.onload = function (loadEvent) {
                    scope.$apply(function () {
                        scope.fileread = loadEvent.target.result;
                    });
                }
                reader.readAsText(changeEvent.target.files[0]);
            });
        }
    }
}]);
