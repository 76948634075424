(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name newvenueanalytics.controller:NewvenueanalyticsCtrl
   *
   * @description
   *
   */
  angular.module('newvenueanalytics').controller('NewvenueanalyticsCtrl', NewvenueanalyticsCtrl);

  function NewvenueanalyticsCtrl(ApiAnalytic, Api, baseurl, $scope, $log, $modal, $window, $sce, $rootScope, $stateParams, $interval, $cookies, $state, Tags) {
    var vm = this;
    vm.tab = 'live';

    vm.venueid = $stateParams.venueid;
    vm.venue = {};
    var date = new Date();
    vm.today = date;
    vm.starttime = new Date(date.getFullYear(), date.getMonth(), 1);
    vm.activityDate = new Date();
    vm.activityDate.setDate(vm.activityDate.getDate() - 1);
    vm.yesterday = new Date();
    vm.yesterday.setDate(vm.yesterday.getDate() - 1);

    vm.endtime = new Date();
    vm.endtime.setHours(23);
    vm.endtime.setMinutes(59);
    vm.endtime.setSeconds(59);
    vm.loading = true;
    vm.dwellLoading = true;
    vm.showActivityZoneTable = false;
    vm.showFootfallTable = false;
    vm.showLoginTable = false;
    vm.showgenderTable = false;
    vm.showDwellTable = false;
    vm.showImpressionsTable = false;
    vm.showProfileTable = false;
    vm.totalImpressions = 0;
    vm.userProfiles = [];
    var tmpProfiles = [];
    vm.tags = [];
    vm.activity = '';

    //user dates
    vm.userStartTime = vm.starttime;
    vm.userEndTime = vm.endtime;
    vm.uniqueUsers = 0;
    vm.dwellStart = vm.starttime;
    vm.dwellEnd = vm.endtime;

    vm.apActivity = {};

    vm.status = {
      openedSt: false,
      openedEnd: false,
      openedHeatMap: false,
      openedJourneyDate: false,
      openedUsrSt: false,
      openedUsrEnd: false,
      openedApCal: false
    };

    vm.dateOptions = {
      formatYear: 'yy',
      startingDay: 0
    };

    vm.activityFilter = [{
      'label': 'Online',
      'value': 'true'
    }, {
      'label': 'Offline',
      'value': 'false'
    }, {
      'label': 'All',
      'value': ''
    }];

    vm.dateFormat = 'dd-MMMM-yyyy';

    vm.openUserCalSt = function () {
      vm.status.openedUsrSt = true;
    };

    vm.openUserCalEnd = function () {
      vm.status.openedUsrEnd = true;
    };

    vm.openCalSt = function () {
      vm.status.openedSt = true;
    };

    vm.openApCal = function () {
      vm.status.openedApCal = true;
    };

    vm.openCalEnd = function () {
      vm.status.openedEnd = true;
    };

    vm.liveDataChart = {
      labels: [
        '', '', '', '', ''
      ],
      series: ['Total'],
      data: [
        [0, 0, 0, 0, 0]
      ],
      datasetOverride: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              suggestedMin: 0
            }
          }]
        }
      }
    };

    vm.apZone = 'zone';

    vm.connectedUsersBarChart = {
      data: [],
      series: [],
      labels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              suggestedMin: 0
            }
          }],
          xAxes: []
        }
      }
    };

    vm.visitorsLoginData = {
      colors: [
        '#45b7cd', '#ff6384', '#ff8e72'
      ],
      labels: [],
      data: [],
      datasetOverride: [{
        label: 'new visitors',
        borderWidth: 1,
        type: 'bar'
      }, {
        label: 'returned visitors',
        borderWidth: 1,
        type: 'bar'
      }, {
        label: 'total visitors',
        borderWidth: 3,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        type: 'line'
      }]
    };

    vm.horizontal = {
      data: [],
      series: [],
      labels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: []
        }
      }
    };

    vm.genderBarChart = {
      data: [],
      series: [],
      labels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: []
        }
      }
    };

    vm.splashLoadChart = {
      data: [],
      series: [
        'Impressions', 'Logins', 'Bounces'
      ],
      labels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: []
        }
      }
    };

    vm.dwellChart = {
      data: [],
      apData: [],
      zoneData: [],
      series: [],
      labels: [],
      apLabels: [],
      zoneLabels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: []
        }
      }
    };

    var getTags = function () {
      Tags.getTags(vm.venueid).then(function (result) {
        vm.tags = result.data.tags.slice();
      });
    };

    vm.dwellZone = 'zone';

    vm.setDwellZone = function (zone) {
      if (zone === 'ap') {
        vm.dwellChart.data = vm.dwellChart.apData;
        vm.dwellChart.series = vm.dwellChart.apLabels;
      } else if (zone === 'zone') {
        vm.dwellChart.data = vm.dwellChart.zoneData;
        vm.dwellChart.series = vm.dwellChart.zoneLabels;
      }
    };

    vm.dwellTable = {
      apheaders: [
        'accesspoints', 'avg dwell time(mins)'
      ],
      zoneheaders: [
        'zones', 'avg dwell time(mins)'
      ],
      aps: [],
      zones: []
    };

    vm.getDwellTime = function (start, end) {
      vm.dwellLoading = true;
      vm.dwellTable.aps = [];
      vm.dwellTable.zones = [];
      vm.dwellChart.apData = [];
      vm.dwellChart.zoneData = [];
      vm.dwellChart.apLabels = [];
      vm.dwellChart.zoneLabels = [];
      ApiAnalytic.getDwellTimeByVenue(vm.venueid, start, end).then(function (response) {
        var data = response.data;
        vm.dwellChart.apData = data.accesspoints.map(function (ap) {
          var newAp = 0;
          vm.dwellChart.apLabels.push(ap.name);
          var value = Math.round((ap.avgconnection / 1000) / 60);
          newAp = [value];
          vm.dwellTable.aps.push({
            name: ap.name,
            time: value
          });
          return newAp;
        });
        vm.dwellChart.zoneData = data.zones.map(function (ap) {
          var newAp = 0;
          vm.dwellChart.zoneLabels.push(ap.name);
          var value = Math.round((ap.avgconnection / 1000) / 60);
          newAp = [value];
          vm.dwellTable.zones.push({
            name: ap.name,
            time: value
          });
          return newAp;
        });
        if (vm.dwellZone === 'zone') {
          vm.dwellChart.data = vm.dwellChart.zoneData;
          vm.dwellChart.series = vm.dwellChart.zoneLabels;
        } else {
          vm.dwellChart.data = vm.dwellChart.apData;
          vm.dwellChart.series = vm.dwellChart.apLabels;
        }
        vm.dwellLoading = false;
      });
    };

    vm.footfallTable = {
      headers: [
        '', 'New Visitors', 'Returning Visitors', 'Total'
      ],
      rows: []
    };

    vm.getAllNewReturningVis = function (start, end) {
      ApiAnalytic.getFootFallByVenue(vm.venueid, start, end).success(function (data) {

        vm.mixedChartOptions = {
          scales: {
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: data.timeScale
              }
            }],
            yAxes: [{
              ticks: {
                beginAtZero: true,
                suggestedMin: 0
              }
            }]
          }
        };
        vm.footfallTable.rows = [];

        var rowItem = {};

        if (data.timeScale === 'Weeks') {
          var labels = [];
          data.labels.shift();
          data.newVisitors.shift();
          data.returningVisitors.shift();
          data.allVisitors.shift();
          for (var i = 0; i < data.labels.length; i++) {
            rowItem = {
              type: '',
              newVisitors: 0,
              returningVisitors: 0,
              total: 0
            };
            labels[i] = 'Week ' + data.labels[i];
            rowItem.type = 'Week ' + data.labels[i];
            rowItem.newVisitors = data.newVisitors[i];
            rowItem.returningVisitors = data.returningVisitors[i];
            rowItem.total = data.allVisitors[i];
            vm.footfallTable.rows.push(rowItem);
          }
          vm.visitorsLoginData.labels = labels;
          vm.visitorsLoginData.data = [data.newVisitors, data.returningVisitors, data.allVisitors];
        } else if (data.timeScale === 'Hours') {
          var hourLabels = [];
          var now = new Date();
          var offset = (now.getTimezoneOffset() * -1) / 60;
          for (var k = 0; k < offset; k++) {
            data.newVisitors.unshift(data.newVisitors[data.newVisitors.length - 1]);
            data.returningVisitors.unshift(data.returningVisitors[data.returningVisitors.length - 1]);
            data.allVisitors.unshift(data.allVisitors[data.allVisitors.length - 1]);
            data.newVisitors.pop();
            data.returningVisitors.pop();
            data.allVisitors.pop();
          }
          for (var j = 0; j < data.labels.length; j++) {
            hourLabels[j] = data.labels[j] + ':00';
            rowItem = {
              type: '',
              newVisitors: 0,
              returningVisitors: 0,
              total: 0
            };
            rowItem.type = data.labels[j] + ':00';
            rowItem.newVisitors = data.newVisitors[j];
            rowItem.returningVisitors = data.returningVisitors[j];
            rowItem.total = data.allVisitors[j];
            vm.footfallTable.rows.push(rowItem);
          }

          vm.visitorsLoginData.labels = hourLabels;
          vm.visitorsLoginData.data = [data.newVisitors, data.returningVisitors, data.allVisitors];
        } else if (data.timeScale === 'Days') {
          // data.labels.shift(); data.newVisitors.shift();
          // data.returningVisitors.shift(); data.allVisitors.shift();
          var dayLabels = [];
          var monthNames = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
          ];

          for (var z = 0; z < data.labels.length; z++) {
            rowItem = {
              type: '',
              newVisitors: 0,
              returningVisitors: 0,
              total: 0
            };
            var date = new Date((new Date().getYear()), 0);
            date.setDate(data.labels[z]);
            dayLabels.push(date.getDate() + ' ' + monthNames[date.getMonth()]);
            rowItem.type = date.getDate() + ' ' + monthNames[date.getMonth()];
            rowItem.newVisitors = data.newVisitors[z];
            rowItem.returningVisitors = data.returningVisitors[z];
            rowItem.total = data.allVisitors[z];
            vm.footfallTable.rows.push(rowItem);
          }

          vm.visitorsLoginData.labels = dayLabels;
          vm.visitorsLoginData.data = [data.newVisitors, data.returningVisitors, data.allVisitors];
        } else {
          data.labels.shift();
          data.newVisitors.shift();
          data.returningVisitors.shift();
          data.allVisitors.shift();

          for (var b = 0; b < data.labels.length; b++) {
            rowItem = {
              type: '',
              newVisitors: 0,
              returningVisitors: 0,
              total: 0
            };
            rowItem.type = data.labels[b];
            rowItem.newVisitors = data.newVisitors[b];
            rowItem.returningVisitors = data.returningVisitors[b];
            rowItem.total = data.allVisitors[b];
            vm.footfallTable.rows.push(rowItem);
          }

          vm.visitorsLoginData.labels = data.labels;
          vm.visitorsLoginData.data = [data.newVisitors, data.returningVisitors, data.allVisitors];
        }
        vm.loading = false;
      }).error(function () {
        $log.info('get gender error');
      });
    };

    vm.genderChartColors = ['#2962ff', '#c51162', '#004d40', '#37474f'];
    vm.typeChartColors = [];
    vm.chartColors = [
      '#b71c1c',
      '#1a237e',
      '#f57f17',
      '#004d40',
      '#212121',
      '#880e4f',
      '#bf360c',
      '#1b5e20',
      '#263238',
      '#4a148c',
      '#0277bd',
      '#3e2723',
      '#558b2f',
      '#616161',
      '#311b92',
      '#ffd740',
      '#c6ff00',
      '#607d8b',
      '#d50000',
      '#0288d1',
      '#ffca28',
      '#795548',
      '#cddc39',
      '#757575',
      '#9c27b0',
      '#26c6da',
      '#ff9100',
      '#d4e157',
      '#7c4dff'
    ];

    vm.getVenue = function () {
      Api.getVenue(vm.venueid).then(function (response) {
        vm.venue = response.data.venue;
        vm.setLive();
      });
    };

    var setLimits = function (n) {
      if (n <= 100) {
        vm.limits = [
          Math.ceil((n / 5) / 10) * 10,
          Math.ceil((n / 5) / 10) * 10 * 2,
          Math.ceil((n / 5) / 10) * 10 * 3,
          Math.ceil((n / 5) / 10) * 10 * 4,
          'all'
        ];
      } else if (n <= 500) {
        vm.limits = [
          Math.ceil((n / 5) / 50) * 50,
          Math.ceil((n / 5) / 50) * 50 * 2,
          Math.ceil((n / 5) / 50) * 50 * 3,
          Math.ceil((n / 5) / 50) * 50 * 4,
          'all'
        ];
      } else {
        vm.limits = [
          100, Math.ceil((n / 5) / 100) * 100,
          Math.ceil((n / 5) / 100) * 100 * 2,
          Math.ceil((n / 5) / 100) * 100 * 3,
          Math.ceil((n / 5) / 100) * 100 * 4,
          'all'
        ];
      }
      vm.limit = vm.limits[0];
    };

    vm.setLimit = function (activity) {
      vm.activity = activity;
      if (activity === 'true') {
        vm.limit = 'all';
      } else {
        vm.limit = vm.limits[0];
      }
    };

    var getUserProfiles = function (start, end) {
      ApiAnalytic.getUserProfilesVenue(vm.venueid, start, end).then(function (response) {
        tmpProfiles = response.data.map(function (user) {
          return user;
        });
        vm.userProfiles = response.data.map(function (user) {
          user.isLive = isLive(user.usermac);
          return user;
        });
        setLimits(response.data.length);
      });
    };

    vm.getMarketingEmailList = function()
    {
      var emailList = [];

      emailList = vm.userProfiles
      .filter(function (userItem){
        return isNaN(userItem.login)
      })
      .map(function (userItem){
          if(userItem.tag && userItem.tag.length > 0)
          {
            return { Name:userItem.name, Email:userItem.login, Tag:userItem.tag[0]}
          }
          else {
            return { Name:userItem.name, Email:userItem.login}
          }
      })
      return emailList;
    };


    function convertArrayOfObjectsToCSV(args) {
            var result, ctr, keys, columnDelimiter, lineDelimiter, data;

            data = args.data || null;
            if (data == null || !data.length) {
                return null;
            }

            columnDelimiter = args.columnDelimiter || ',';
            lineDelimiter = args.lineDelimiter || '\n';

            keys = Object.keys(data[0]);

            result = '';
            result += keys.join(columnDelimiter);
            result += lineDelimiter;

            data.forEach(function(item) {
                ctr = 0;
                keys.forEach(function(key) {
                    if (ctr > 0) result += columnDelimiter;

                    result += item[key];
                    ctr++;
                });
                result += lineDelimiter;
            });

            return result;
    }

    vm.downloadCSV = function()
    {
      var data, filename, link;
      var csv = convertArrayOfObjectsToCSV({
        data: vm.getMarketingEmailList()
      });
      if (csv == null)  return;

      var now = new Date();
      var nowString = now.toISOString().split('.')[0];
      filename = 'export_'+nowString+'.csv';

      var blob = new Blob([csv], {type: "text/csv;charset=utf-8;"});

      if (navigator.msSaveBlob)
      { // IE 10+
        navigator.msSaveBlob(blob, filename)
      }
      else
      {
          var link = document.createElement("a");
          if (link.download !== undefined)
          {

            // feature detection, Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style = "visibility:hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
      }
    }


    vm.getSplashLoadChartData = function (start, end) {
      ApiAnalytic.getImpressionsByVenue(vm.venueid, start, end).then(function successCallback(response) {
        var data = JSON.parse(JSON.stringify(response.data));
        vm.totalImpressions = data.totalImpressions;
        vm.splashLoadChart.data = [];
        vm.splashLoadChart.labels = [];
        var monthNames = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ];
        // Sort Returned Data
        data.impressions.sort(function sortNumber(a, b) {
          return a._id[
            data.type.substring(0, data.type.length - 1)
          ] - b._id[
            data.type.substring(0, data.type.length - 1)
          ];
        });
        data.logins.sort(function sortNumber(a, b) {
          return a._id[
            data.type.substring(0, data.type.length - 1)
          ] - b._id[
            data.type.substring(0, data.type.length - 1)
          ];
        });
        data.bounces.sort(function sortNumber(a, b) {
          return a._id[
            data.type.substring(0, data.type.length - 1)
          ] - b._id[
            data.type.substring(0, data.type.length - 1)
          ];
        });

        // correct the data set
        var impressions = [];
        var logins = [];
        var bounces = [];

        data.impressions.forEach(function (item) {
          var label = item._id[
            data.type.substring(0, data.type.length - 1)
          ];
          if (data.type === 'hours') {
            vm.splashLoadChart.labels.push(label + ':00');
          } else if (data.type === 'days') {
            var date = new Date((new Date().getYear()), 0);
            date.setDate(label);
            vm.splashLoadChart.labels.push(date.getDate() + ' ' + monthNames[date.getMonth()]);
          } else if (data.type === 'months') {
            vm.splashLoadChart.labels.push(monthNames[label - 1]);
          } else if (data.type === 'years') {
            var year = new Date(label, 0).getFullYear();
            vm.splashLoadChart.labels.push('' + year);
          } else {
            vm.splashLoadChart.labels.push(data.type.charAt(0).toUpperCase() + data.type.substring(1, data.type.length - 1) + ' ' + label);
          }
          impressions.push(item.count);
        });
        data.logins.forEach(function (item) {
          logins.push(item.count);
        });
        data.bounces.forEach(function (item) {
          bounces.push(item.count);
        });

        // ofsetcorrection temp
        if (data.type === 'hours') {
          impressions.unshift(impressions[impressions.length - 1]);
          logins.unshift(logins[logins.length - 1]);
          bounces.unshift(bounces[bounces.length - 1]);

          impressions.pop();
          logins.pop();
          bounces.pop();
        }

        vm.splashLoadChart.data.push(impressions);
        vm.splashLoadChart.data.push(logins);
        vm.splashLoadChart.data.push(bounces);
      }, function errorCallback(response) {
        console.log(response);
      });
    };

    vm.connectedTable = {
      zoneheaders: [
        'zones', 'count'
      ],
      zones: []
    };

    vm.getLiveAnalytics = function () {
      vm.connectedUsersBarChart.series = [];
      vm.connectedTable.zones = [];
      vm.connectedUsersBarChart.data = [];
      vm.connectedUsersBarChart.options.scales.xAxes = [];

      ApiAnalytic.getClientsOnlineByVenue(vm.venueid, vm.venue.infrastructure.provider).then(function (response) {
        var expireDate = new Date((new Date()).getTime() + 10 * 60000);
        $cookies.put('unifitoken', response.data.cookie, {
          'expires': expireDate
        });

        /* Live User Totals */
        vm.liveLoading = false;

        // total
        vm.liveDataChart.data[0].push(response.data.totalUsers);
        vm.liveDataChart.data[0].shift();
        //ssids
        response.data.ssids.forEach(function (ssid) {
          if (vm.liveDataChart.series.indexOf(ssid.name) === -1) {
            vm.liveDataChart.series.push(ssid.name);
            var tempArray = [0, 0, 0, 0, 0];
            tempArray.push(ssid.count);
            tempArray.shift();
            vm.liveDataChart.data.push(tempArray);
          } else {
            var index = vm.liveDataChart.series.indexOf(ssid.name);
            vm.liveDataChart.data[index].push(ssid.count);
            vm.liveDataChart.data[index].shift();
          }
        });

        vm.totalUsers = response.data.totalUsers;
        vm.authedUsers = response.data.authedUsers;

        var dataLength = response.data.zones.length;
        for (var item = 0; item < dataLength; item++) {
          var newAp = 0;
          var value = response.data.zones[item].count;
          newAp = [value];
          var name = response.data.zones[item].name;
          vm.connectedUsersBarChart.series.push(name);
          vm.connectedUsersBarChart.data.push(newAp);
          vm.connectedTable.zones.push({
            name: name,
            count: value
          });
        }
        if (vm.connectedUsersBarChart.data.length <= 1) {
          vm.connectedUsersBarChart.options.scales.xAxes.push({
            barPercentage: 0.3
          });
        } else {
          vm.connectedUsersBarChart.options.scales.xAxes = [];
        }
        vm.setHistoric(vm.starttime.getTime(), vm.endtime.getTime());
      }).catch(function (error) {
        if (error.data.message === 'This Provider is Not Supported') {
          vm.liveData = false;
        }
      });
    };

    var getApActivity = function (date) {
      vm.apActivity = {};
      vm.AccessPointsTable = {};
      Api.getAccessPoints(vm.venueid).then( function (res) {
        for ( var i = 0 ; i < res.data.rowCount ; i++)
        {
          vm.AccessPointsTable[res.data.apoints[i].hwaddr] = res.data.apoints[i].name;
        }
        ApiAnalytic.getApActivity(vm.venueid, date).then(function (res) {
          vm.apActivity = res.data;
          for (var i = 0 ; i < vm.apActivity.series.length ; i++)
          {
            vm.apActivity.series[i] = vm.AccessPointsTable[vm.apActivity.series[i]];
          }
          vm.apActivity.options = {
            legend: {
              display: true,
              position: 'left'
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  suggestedMin: 0
                }
              }]
            }
          };
        });
      })
    };

    var getBusyAP = function (date) {
      ApiAnalytic.getBusyAP(vm.venueid, date).then(function (res) {
        vm.busyAp = res.data;
        if(vm.AccessPointsTable){
          busyAp.mostBusy.ap  = vm.AccessPointsTable[busyAp.mostBusy.ap];
          busyAp.leastBusy.ap  = vm.AccessPointsTable[busyAp.leastBusy.ap];
        }
      });
    };

    var getJourney = function () {
      var date = new Date();
      date.setDate(date.getDate() - 1);
      var dateN = date.getTime();
      ApiAnalytic.getJourney(vm.venueid, dateN).then(function (res) {
        var aps = res.data.aps;
        var length = aps.length;
        var labels = [];
        var data = [];
        for (var i = 0; i < length; i++) {
          if (data.length < 1) {
            for (var k = 0; k < 10; k++) {
              var key = k + 1;
              labels.push(aps[0].apMac);
              data.push(aps[0].position[key]);
            }
          } else {
            for (var j = 0; j < data.length; j++) {
              var newKey = j + 1;
              if (aps[i].position[newKey] > data[j]) {
                var test = j - 1;
                if (labels[test] !== aps[i].apMac) {
                  data[j] = aps[i].position[newKey];
                  labels[j] = aps[i].apMac;
                }
              }
            }
          }
        }
        vm.usrJourney = {
          labels: labels,
          data: [data],
          series: ['Users']
        };
        vm.usrJourney.options = {
          legend: {
            display: false,
            position: 'right'
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                suggestedMin: 0
              }
            }]
          }
        };
        // vm.usrJourney.options.elements = {
        //   line: {
        //     fill: false
        //   }
        // };
      });
    };

    var intervalPromise;

    vm.startInterval = function () {
      vm.stopInterval();
      intervalPromise = $interval(function () {
        vm.setLive();
      }, 30000);
    };

    vm.stopInterval = function () {
      $interval.cancel(intervalPromise);
    };

    $rootScope.$on('$stateChangeStart', function () {
      vm.stopInterval();
      return;
    });

    vm.setLive = function () {
      vm.getLiveAnalytics();
      getLiveUsers();
      refreshLive();
      vm.startInterval();
    };
    vm.genderTable = {
      headers: [
        'demographic', 'count'
      ],
      rows: []
    };

    var processGender = function (genderArray) {
      if (genderArray.length <= 1) {
        vm.genderBarChart.options.scales.xAxes.push({
          barPercentage: 0.3
        });
      } else {
        vm.genderBarChart.options.scales.xAxes = [];
      }
      var total = parseInt(genderArray[0].female) + parseInt(genderArray[0].male);
      if (parseInt(genderArray[0].male) > 0) {
        vm.genderMale = Math.round((parseInt(genderArray[0].male) / total) * 100) + '%';
      } else if (parseInt(genderArray[0].male) <= 0 && parseInt(genderArray.female) > 0) {
        vm.genderMale = 0 + '%';
      } else {
        vm.genderMale = 'No Data';
      }

      if (parseInt(genderArray[0].female) > 0) {
        vm.genderFemale = Math.round((parseInt(genderArray[0].female) / total) * 100) + '%';
      } else if (parseInt(genderArray[0].female) <= 0 && parseInt(genderArray[0].male) > 0) {
        vm.genderFemale = 0 + '%';
      } else {
        vm.genderFemale = 'No Data';
      }
      var male = parseInt(genderArray[0].male);
      var female = parseInt(genderArray[0].female);
      var other = parseInt(genderArray[0].other);

      vm.genderBarChart.series = [];
      vm.genderBarChart.data = [];
      vm.genderTable.rows = [];

      var rowMale = {
        type: 'Male',
        count: male
      };
      var rowFemale = {
        type: 'Female',
        count: female
      };
      var rowOther = {
        type: 'Other',
        count: other
      };

      if (male > 0) {
        vm.genderBarChart.series.push('Male');
        vm.genderBarChart.data.push([male]);
        vm.genderTable.rows.push(rowMale);
      }
      if (female > 0) {
        vm.genderBarChart.series.push('Female');
        vm.genderBarChart.data.push([female]);
        vm.genderTable.rows.push(rowFemale);
      }
      if (other > 0) {
        vm.genderBarChart.series.push('Other');
        vm.genderBarChart.data.push([other]);
        vm.genderTable.rows.push(rowOther);
      }

      if (vm.genderBarChart.data.length <= 1) {
        vm.genderBarChart.options.scales.xAxes.push({
          barPercentage: 0.3
        });
      } else {
        vm.genderBarChart.options.scales.xAxes = [];
      }
    };

    vm.loginTable = {
      headers: [
        'type', 'count'
      ],
      rows: []
    };

    var processLoginTypes = function (logins) {
      vm.horizontal.series = [];
      vm.horizontal.data = [];
      vm.loginTable.rows = [];
      for (var x = 0; x < logins.length; x++) {
        var rowItem = {
          type: '',
          count: 0
        };
        vm.horizontal.data.push([parseInt(logins[x].count)]);
        rowItem.count = parseInt(logins[x].count);
        var type = logins[x].logintype;
        if (type === 'anonymous') {
          vm.horizontal.series.push('email');
          rowItem.type = 'email';
        } else {
          vm.horizontal.series.push(type);
          rowItem.type = type;
        }
        vm.loginTable.rows.push(rowItem);
        switch (type) {
          case 'anonymous':
            vm.typeChartColors.push('#455a64');
            break;
          case 'facebook':
            vm.typeChartColors.push('#304ffe');
            break;
          case 'linkedin':
            vm.typeChartColors.push('#1e88e5');
            break;
          case 'twitter':
            vm.typeChartColors.push('#00b0ff');
            break;
          case 'google':
            vm.typeChartColors.push('#d50000');
            break;
          case 'twilio':
            vm.typeChartColors.push('#263238');
            break;
        }
      }
    };

    var getLoginAnalytics = function (start, end) {
      ApiAnalytic.getLoginAnalyticsVenue(vm.venueid, start, end).then(function (response) {
        vm.uniqueUsers = response.data.unique;
        vm.totalLogin = response.data.total;
        processGender(response.data.gender);
        processLoginTypes(response.data.loginType);
      });
    };

    vm.setHistoric = function (start, end) {
      vm.getAllNewReturningVis(start, end);
      vm.getSplashLoadChartData(start, end);
      vm.getDwellTime(start, end);
      getLoginAnalytics(start, end);
    };

    var getLiveUsers = function () {
      ApiAnalytic.getOnlineUsersVenue(vm.venueid, vm.venue.infrastructure.provider).then(function (result) {
        vm.liveUsers = result.data;
        if (vm.userProfiles.length <= 0) {
          var thisStart = vm.starttime;
          var thisEnd = vm.endtime;
          thisStart.setHours(0);
          thisStart.setMinutes(0);
          thisStart.setSeconds(0);
          thisEnd.setHours(23);
          thisEnd.setMinutes(59);
          thisEnd.setSeconds(59);
          getUserProfiles(thisStart.getTime(), thisEnd.getTime());
        }
      });
    };

    var refreshLive = function () {
      if (tmpProfiles.length > 0) {
        vm.userProfiles = tmpProfiles.map(function (user) {
          user.isLive = isLive(user.usermac);
          return user;
        });
      } else {
        var thisStart = vm.starttime;
        var thisEnd = vm.endtime;
        thisStart.setHours(0);
        thisStart.setMinutes(0);
        thisStart.setSeconds(0);
        thisEnd.setHours(23);
        thisEnd.setMinutes(59);
        thisEnd.setSeconds(59);
        getUserProfiles(thisStart.getTime(), thisEnd.getTime());
      }
    };

    var isLive = function (macarray) {
      var length = macarray.length;
      for (var i = 0; i < length; i++) {
        if (vm.liveUsers.indexOf(macarray[i]) >= 0) {
          return 'true';
        }
      }
      return 'false';
    };

    vm.setUsers = function (start, end) {
      getUserProfiles(start, end);
    };

    vm.refresh = function () {
      vm.loading = true;
      var thisStart = vm.starttime;
      var thisEnd = vm.endtime;
      vm.visitorsLoginData.data = [];
      thisStart.setHours(0);
      thisStart.setMinutes(0);
      thisStart.setSeconds(0);
      thisEnd.setHours(23);
      thisEnd.setMinutes(59);
      thisEnd.setSeconds(59);
      vm.setHistoric(thisStart.getTime(), thisEnd.getTime());
      vm.setUsers(thisStart.getTime(), thisEnd.getTime());
    };

    vm.switchTab = function (tab) {
      switch (tab) {
        case 'live':
          vm.tab = tab;
          break;
        case 'historic':
          vm.tab = tab;
          break;
        case 'users':
          vm.tab = tab;
          break;
        case 'Segmentation':
          $state.go('admin.tags', {
          'venueid': vm.venueid
        });
          break;
        default:
          vm.tab = 'live';
      }
    };

    vm.setClass = function () {
      if ($stateParams.class) {
        vm.tab = $stateParams.class;
      } else {
        vm.tab = 'live';
      }
    };

    /* Heat Map */
    vm.getMessage = function () {
      switch (vm.mapType.toLowerCase()) {
        case 'users':
          return 'users';
          break;
        case 'dwell':
          return 'dwell';
          break;
        case 'active minutes':
          return 'active minutes';
          break;
        default:

      }
    };
    // Data
    vm.maps = [];
    vm.heatmap = [];
    vm.map = {};
    vm.mapHeight = 480;
    vm.mapWidth = 854;
    vm.heatMapDay = [];
    vm.heatMapDate = new Date().setDate((new Date()).getDate() - 1);
    vm.heatmapSlider = {
      value: 0,
      options: {
        showTicks: true,
        floor: 0,
        ceil: 23,
        translate: function (value) {
          if (value < 10) {
            value = '0' + value;
          }
          return value + ':00';
        }
      }
    };
    vm.mapType = 'Users';
    vm.heatMax = 0;
    vm.scaleHeight = 0;
    vm.scaleTextHeight = 0;
    vm.drawAps = false;
    vm.originalPoints = [];
    vm.toolTipMessage = vm.getMessage();
    vm.heatmapData = [];

    // Page Objects
    var mapBackground, mapHeatMap, mapPointMap, mapContainer, mapClockMap;

    // Context
    var backCtx, pointCtx, heatCtx, clockCtx;

    // Image Data
    vm.backdrop = '';
    vm.backgroundSelected = false;
    vm.apicon = './images/apicon.png';
    vm.logoLoaded = false;
    vm.percent = 0;
    var logoImage = new Image();

    vm.initMap = function () {
      mapBackground = document.getElementById('mapBackground');
      mapHeatMap = document.getElementById('mapHeatMap');
      mapClockMap = document.getElementById('mapHeatMap');
      mapPointMap = document.getElementById('mapPointMap');
      mapContainer = document.getElementById('mapContainer');

      backCtx = mapBackground.getContext('2d');
      pointCtx = mapPointMap.getContext('2d');
      heatCtx = mapHeatMap.getContext('2d');
      clockCtx = mapClockMap.getContext('2d');
      backCtx.globalAlpha = 1.0;
      pointCtx.globalAlpha = 1.0;
      heatCtx.globalAlpha = 1.0;
      vm.drawBackground();
    };

    vm.getMaps = function () {
      ApiAnalytic.getMapsByVenue(vm.venueid).then(function (res) {
        if (res.data.length > 0) {
          vm.maps = res.data;
          vm.map = vm.maps[0];
          vm.originalPoints = JSON.stringify(vm.map.points);
        }
      }, function (err) {
        console.log(err);
      });
    };

    vm.typeChange = function () {
      vm.toolTipMessage = vm.getMessage();
      vm.getHeatMap();
    };

    vm.mapChange = function () {
      vm.originalPoints = JSON.stringify(vm.map.points);
      vm.drawBackground();
    };

    vm.getHeatMap = function () {
      vm.heatMapDay = [];
      var macarray = [];
      vm.map.points.forEach(function (item) {
        macarray.push(item.hwaddr);
      });
      var date = new Date(vm.heatMapDate);
      vm.heatMax = 0;
      ApiAnalytic.getMapForDay(vm.mapType, vm.venueid, date, macarray).then(function (res) {
        res.data.mapData.forEach(function (point) {
          var dataLength = point.mapdata.length;
          var index = 0;
          var hour = {
            hour: point.hour,
            mapdata: []
          };
          for (index; index < dataLength; index++) {
            vm.map.points.forEach(function (ap) {
              if (ap.hwaddr === point.mapdata[index].mac) {
                vm.heatMax = (point.mapdata[index].weight > vm.heatMax) ? point.mapdata[index].weight : vm.heatMax;
                hour.mapdata.push([ap.x, ap.y, point.mapdata[index].weight]);
              }
            });
          }
          vm.heatMapDay.push(hour);
        });
        vm.renderHour();
      }, function (err) {
        console.log(err);
      });
    };

    vm.renderHour = function () {
      vm.heatmap = [];
      vm.heatmap = vm.heatMapDay[vm.heatmapSlider.value].mapdata;
      vm.draw(vm.map.points);
    };

    // Draw Functions
    vm.draw = function (data) {
      pointCtx.clearRect(0, 0, mapPointMap.width, mapPointMap.height);

      for (var i = 0; i < data.length; i++) {
        vm.drawAp(vm.heatmap[i]);
      }

      $window.requestAnimationFrame(vm.drawHeatmap);
    };

    function degreesToRadians(degrees) {
      return degrees * (Math.PI / 180);
    }

    vm.drawAp = function (data) {
      if (vm.mapType === 'Dwell') {
        if (data) {
          pointCtx.beginPath();
          console.log(data);

          var angle = (360 / 60) * (data[2] / 60000);

          pointCtx.lineWidth = 1;
          pointCtx.arc(data[0], data[1], (vm.mapWidth / 28), 0, 360 * 0.0174533);

          pointCtx.stroke();
          pointCtx.closePath();

          pointCtx.fillStyle = 'red';
          pointCtx.beginPath();
          pointCtx.moveTo(data[0], data[1]);
          pointCtx.arc(data[0], data[1], (vm.mapWidth / 28), degreesToRadians(0) - Math.PI / 2, degreesToRadians(angle) - Math.PI / 2);
          pointCtx.fill();
          pointCtx.closePath();

          pointCtx.beginPath();

          pointCtx.lineWidth = 5;
          // Top Line
          pointCtx.moveTo(data[0], data[1] - (vm.mapWidth / 28) + 3);
          pointCtx.lineTo(data[0], data[1] - (vm.mapWidth / 28) - 3);

          // Bottom Line
          pointCtx.moveTo(data[0], data[1] + (vm.mapWidth / 28) + 3);
          pointCtx.lineTo(data[0], data[1] + (vm.mapWidth / 28) - 3);

          // Left Line
          pointCtx.moveTo(data[0] - (vm.mapWidth / 28) + 3, data[1]);
          pointCtx.lineTo(data[0] - (vm.mapWidth / 28) - 3, data[1]);

          // Right Line
          pointCtx.moveTo(data[0] + (vm.mapWidth / 28) + 3, data[1]);
          pointCtx.lineTo(data[0] + (vm.mapWidth / 28) - 3, data[1]);
          //pointCtx.arc(data[0], data[1], (vm.mapWidth/32), 0, (60/360) * (data[2]/60000));

          pointCtx.stroke();
          pointCtx.closePath();
        }
      }
    };

    vm.drawBackground = function () {
      backCtx.clearRect(0, 0, mapBackground.width, mapBackground.height);
      var background = new Image();

      background.onload = function () {
        background.height = vm.map.originalHeight;
        background.width = vm.map.originalWidth;

        if (mapContainer.offsetWidth < background.width) {
          var max = background.width;
          var reduced = mapContainer.offsetWidth;
          var diff = max - reduced;
          vm.percent = (diff / max) * 100;
          background.width = reduced;
          background.height = (background.height / 100) * (100 - vm.percent);
          //background.height = parseInt((background.height/100)*percent);
        } else {
          var min = background.width;
          var scaled = mapContainer.offsetWidth;
          var diff = scaled - min;
          vm.percent = (diff / min) * 100;
          background.width = scaled;
          background.height = background.height + (background.height / 100) * vm.percent;
        }

        vm.map.points = JSON.parse(vm.originalPoints);

        vm.map.points.forEach(function (ap) {
          ap.x = ap.x * (background.width / vm.map.originalWidth);
          ap.y = ap.y * (background.height / vm.map.originalHeight);
        });

        vm.mapHeight = background.height;
        vm.mapWidth = background.width;

        mapPointMap.width = vm.mapWidth;
        mapPointMap.height = vm.mapHeight;
        mapBackground.width = vm.mapWidth;
        mapBackground.height = vm.mapHeight;
        mapClockMap.width = vm.mapWidth;
        mapClockMap.height = vm.mapHeight;
        mapHeatMap.width = vm.mapWidth;
        mapHeatMap.height = vm.mapHeight;

        vm.scaleHeight = vm.mapHeight * .6;
        vm.scaleTextHeight = vm.scaleHeight * .98;

        $scope.$apply();

        backCtx.drawImage(background, 0, 0, vm.mapWidth, vm.mapHeight);
        vm.logoLoaded = true;
        vm.getHeatMap();
      };
      background.src = baseurl + '/' + vm.map.imageUri;
    };

    vm.drawHeatmap = function () {
      heatCtx.clearRect(0, 0, mapHeatMap.width, mapHeatMap.height);
      var heat = simpleheat(mapHeatMap);
      // find max

      heat.max(vm.heatMax);
      // set radius
      heat.radius(vm.mapWidth / 12, vm.mapWidth / 12);

      heat.data(vm.heatmap);

      vm.scaleMax = (vm.mapType === 'Active Minutes') ? parseInt(vm.heatMax / 60000) : vm.heatMax;

      $scope.$apply();

      heat.draw();
    };

    // Slider
    $scope.$on('slideEnded', function () {
      vm.renderHour();
    });

    // Date Picker
    vm.mapDayChange = function () {
      vm.getHeatMap();
    };
    vm.openCalHeatMap = function () {
      vm.status.openedHeatMap = true;
    };

    vm.refreshApActivity = function () {
      getApActivity(vm.activityDate.getTime());
      getBusyAP(vm.activityDate.getTime());
    };

    vm.getAPs = function ()
    {
      vm.AccessPointsTable = {};
      Api.getAccessPoints(vm.venueid).then( function (res) {
        for ( var i = 0 ; i < res.rowCount ; i++)
        {
          vm.AccessPointsTable[res.apoints[i].hwaddr] = res.apoints[i].name;
        }
      })
    };

    vm.getVenue();
    vm.setClass();
    getTags();
    vm.getMaps();
    vm.getAPs();
    getApActivity(vm.activityDate.getTime());
    getBusyAP(vm.activityDate.getTime());
  }
}());
