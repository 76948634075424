<div id="wrapper" ng-class="{'toggled' : toggle}">
  <!-- Sidebar -->
  <div class="hidden-xs">
    <div id="sidebar-wrapper">
      <ul class="sidebar-nav">
        <li class="sidebar-header">
          <a data-ui-sref="admin.events">
            <!-- class="modal-img" -->
            <!-- <img src="./images/connect2fi/connect2filogo_brand_logo.png"> -->
            <!-- <div class="row">
                           
            </div> -->
          </a>
        </li>
        <li title="Events">
          <a data-ui-sref="admin.events">
            <div class="row">
              <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">
                <i class="fa fa-calendar-o"></i>
              </div>
              <div class="col-xs-14 col-sm-10 col-ms-10 text-row">
                Events
              </div>
            </div>
          </a>
        </li>
        <li title="Images">
          <a data-ui-sref="admin.manageimages">
            <div class="row">
              <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">
                <i class="fa fa-picture-o"></i>
              </div>
              <div class="col-xs-14 col-sm-10 col-ms-10 text-row">
                Images
              </div>
            </div>
          </a>
        </li>
        <!--<li>
        <a href="">
          <div class="row">
            <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">
              <i class="fa fa-bullhorn"></i>
            </div>
            <div class="col-xs-14 col-sm-10 col-ms-10 text-row">
              Ads
            </div>
          </div>
        </a>
      </li> -->
        <!--<li>
        <a data-ui-sref="admin.analytics">
          <div class="row">
            <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">
              <i class="fa fa-bar-chart"></i>
            </div>
            <div class="col-xs-14 col-sm-10 col-ms-10 text-row">
              Reports
            </div>
          </div>
        </a>
      </li> -->
        <li title="Operators" ng-hide="activeUser.role === 2">
          <a data-ui-sref="admin.operators">
            <div class="row">
              <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">
                <i class="fa fa-user"></i>
              </div>
              <div class="col-xs-14 col-sm-10 col-ms-10 text-row">
                Operators
              </div>
            </div>
          </a>
        </li>
        <li title="Venues" ng-hide="activeUser.role === 2">
          <a data-ui-sref="admin.venues">
            <div class="row">
              <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">
                <i class="fa fa-building"></i>
              </div>
              <div class="col-xs-14 col-sm-10 col-ms-10 text-row">
                Venues
              </div>
            </div>
          </a>
        </li>
        <li title="Infrastructures" ng-hide="activeUser.role === 2">
          <a data-ui-sref="admin.orgInfrastructures">
            <div class="row">
              <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">
                <i class="fa fa-wifi"></i>
              </div>
              <div class="col-xs-14 col-sm-10 col-ms-10 text-row">
                Infrastructures
              </div>
            </div>
          </a>
        </li>
        <li title="Venue Analytics" ng-hide="activeUser.role === 1">
          <a data-ui-sref="admin.newvenueanalytics({venueid: activeUser.details.venue})">
            <div class="row">
              <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">
                <i class="fa fa-line-chart"></i>
              </div>
              <div class="col-xs-14 col-sm-10 col-ms-10 text-row">
                {{activeUser.details.venueName}}
              </div>
            </div>
          </a>
        </li>
        <li title="Customer Segmentation" ng-hide="activeUser.role === 1">
          <a data-ui-sref="admin.tags({venueid: activeUser.details.venue})">
            <div class="row">
              <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">
                <i class="fa fa-user-plus"></i>
              </div>
              <div class="col-xs-14 col-sm-10 col-ms-10 text-row">
                Segmentation
              </div>
            </div>
          </a>
        </li>
        <li title="terms">
          <a data-ui-sref="admin.tandceditor({venueid: activeUser.details.venue})">
            <div class="row">
              <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">
                <i class="fa fa-file"></i>
              </div>
              <div class="col-xs-14 col-sm-10 col-ms-10 text-row">
                Terms &amp; Conditions
              </div>
            </div>
          </a>
        </li>
        <!-- <li>
          <a data-ui-sref="admin.tags">
            <div class="row">
              <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">
                <i class="fa fa-bullhorn"></i>
              </div>
              <div class="col-xs-14 col-sm-10 col-ms-10 text-row">
                {{activeUser.details.venueName}}
              </div>
            </div>
          </a>
        </li> -->
        <!-- <li>
          <a href="">
            <div class="row">
              <div class="col-xs-4 col-sm-2 col-ms-2 icon-align">
                <i class="fa fa-mobile fa-lg"></i>
              </div>
              <div class="col-xs-14 col-sm-10 col-ms-10 text-row">
                Send Message
              </div>
            </div>
          </a>
        </li> -->
      </ul>
    </div>
  </div>
  <!-- /#sidebar-wrapper -->
  <!-- Page Content -->
  <div id="page-content-wrapper">
    <div class="hidden-xs">
      <nav class="navbar navbar-default navbar-static-top">
        <div class="container-fluid">
          <div class="navbar-header">
            <a class="navbar-brand" data-ng-init='toggle = false' ng-click="toggle = !toggle" href="">
              <i class="fa fa-bars"></i>
            </a>
          </div>
          <ul class="nav navbar-nav navbar-right">
            <li dropdown is-open="it.isOpen" class="dropdownWidth">
              <a href="" dropdown-toggle ng-disabled="isDisabled" role="button">{{admin.profileInfo.name}}
              -
              <span ng-show="admin.profileInfo.role === 2">{{admin.profileInfo.details.venueName}}</span>
              <span ng-show="admin.profileInfo.role === 1">Admin</span>
              <span class="caret"></span>
            </a>
              <ul class="dropdown-menu options-menu">
                <li role="separator" class="divider"></li>
                <li>
                  <a href="" ui-sref="admin.editoperator({operatorid: admin.profileInfo.id})">
                    <i class="fa fa-user"></i> Profile
                  </a>
                </li>
                <li role="separator" class="divider"></li>
                <li>
                  <a href="" ng-click="admin.support(activeUser.details.venue)">
                    <i class="fa fa-pencil-square-o"></i> Support</a>
                </li>
                <li role="separator" class="divider"></li>
                <li>
                  <a href="" ng-click="admin.logout()">
                    <i class="fa fa-sign-out"></i> Log Out</a>
                </li>
                <li role="separator" class="divider"></li>
              </ul>
            </li>
            <!--<li class="profileCircle">
            <img class="img-circle img-responsive" alt="Brand" ng-src="{{admin.profileInfo.userImg}}">
          </li> -->
          </ul>
        </div>
      </nav>
    </div>
    <div class=" hidden-sm hidden-md hidden-lg">
      <div class="navbar navbar-default">
        <div class="row">
          <div class="col-xs-8">
            <div class="navbar-header">
              <a class="navbar-brand no-padding" data-ui-sref="admin.events">
                <img class="img-responsive" alt="Liquidedge" src="./images/connect2fi/connect2filogo_brand.png">
              </a>
            </div>
          </div>
          <div class="col-xs-2 col-xs-offset-2">
            <div class="btn-group" dropdown is-open="admin.status.isopen">
              <a href="" id="single-button" class="sm-toggle" dropdown-toggle ng-disabled="disabled">
                <i class="fa fa-bars fa-2x" aria-hidden="true"></i>
              </a>
              <ul class="dropdown-menu" role="menu" aria-labelledby="single-button">
                <li role="menuitem">
                  <a href="" ui-sref="admin.editoperator({operatorid: admin.profileInfo.id})">
                    <i class="fa fa-user"></i> Profile
                  </a>
                </li>
                <li role="menuitem">
                  <a href="" ng-click="admin.support(activeUser.details.venue)">
                    <i class="fa fa-question" aria-hidden="true"></i> Support</a>
                </li>
                <li class="divider"></li>
                <li role="menuitem">
                  <a href="" ng-click="admin.logout()">
                    <i class="fa fa-sign-out"></i> Log Out</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div data-ui-view></div>
    </div>
  </div>
  <div class="navbar navbar-default nav-btm navbar-fixed-bottom hidden-sm hidden-md hidden-lg">
    <div class="container">
      <div class="btn-group btn-group-justified" role="group" aria-label="...">
        <div class="btn-group" role="group" aria-label="...">
          <button type="button" class="btn btn-default" data-ui-sref="admin.events" ng-class="admin.getClass('/admin/events')"><i class="fa fa-calendar-o"></i> Events</button>
        </div>
        <div class="btn-group" role="group" aria-label="..." ng-hide="activeUser.role === 1">
          <button type="button" class="btn btn-default" data-ui-sref="admin.newvenueanalytics({venueid: activeUser.details.venue})" ng-class="admin.getClass('/admin/newvenueanalytics')"><i class="fa fa-bar-chart" aria-hidden="true"></i> Analytics</button>
        </div>
        <div class="btn-group" role="group" aria-label="..." ng-hide="activeUser.role === 2">
          <button type="button" class="btn btn-default" data-ui-sref="admin.venues" ng-class="admin.getClass('/admin/venues')"><i class="fa fa-building"></i> Venues</button>
        </div>
      </div>
    </div>
  </div>
</div>