<div class="modal-header">
  <img class="modal-img" src="./images/connect2fi/connect2filogo_small.png">
</div>
<div class="modal-body">
  <h4>Event duration is less than one hour.</h4>
  <h4>Would you like to confirm this duration or change it?</h4>
</div>
<div class="modal-footer">
  <button class="btn btn-danger" type="button" ng-click="cancelModal.ok()">Confirm</button>
  <button class="btn btn-warning" type="button" ng-click="cancelModal.cancel()">Change</button>
</div>