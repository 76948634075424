<div class="modal-header">
  <img class="modal-img" src="./images/connect2fi/connect2filogo_small.png">
</div>
<div class="modal-body">
  <h4>{{modalService.message.messageHeader}}</h4>
  <p>{{modalService.message.messageBody}}</p>
  <table class="table table-responsive eventsTable">
    <thead>
      <tr>
        <th>Access Point</th>
        <th>Zone</th>
      </tr>
    </thead>
    <tbody>
      <tr ng-repeat="ap in modalService.message.accesspoints">
        <td>
          {{ap.ipaddress}}
        </td>
        <td>
          {{ap.zonename}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <div ng-hide="modalService.message.cancel.length > 0">
    <button class="btn btn-primary btn-block" type="button" ng-click="modalService.ok()">Done</button>
  </div>
</div>