(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editeventnew.controller:EventsplashmodalCtrl
   *
   * @description
   *
   */
  angular
    .module('editeventnew')
    .controller('EventsplashmodalCtrl', EventsplashmodalCtrl);

  function EventsplashmodalCtrl(params, $modalInstance, Api) {
    var vm = this;
    vm.ctrlName = 'EventsplashmodalCtrl';
    vm.params = params;
    vm.isMagnet = vm.params.isMagnet;
    vm.venue = {};
    vm.defaultFbLikeUrl = true;

    vm.anonymousLogin = vm.params.anonymousLogin;

    Api.getVenue(params.venueid).success(function (data) {
      var detailsJson = {};
      if(typeof data.venue.details === 'string'){
        detailsJson = JSON.parse(data.venue.details);
      } else {
        detailsJson = data.venue.details;
      }
      if(detailsJson){
        vm.venue.customFbLikeUrl = detailsJson.fbpage;
        if (vm.params.splashDetails.customFbLikeUrl === vm.venue.customFbLikeUrl){
          vm.defaultFbLikeUrl = true;
        } else {
          vm.customFbLike = true;
          if (!vm.params.splashDetails.customFbLikeUrl) {
            vm.customFbLike = false;
          }
        }
      } else {
        vm.defaultFbLikeUrl = false;
        vm.customFbLike = true;
      }
    });

    vm.setSplashPageType = function (type, set) {
      vm.spVideo = false;
      vm.spSocial = false;
      vm.spAmount = false;
      vm.spEmail = false;
      vm.spLink = false;
      vm.spRegister = false;

      if (vm.params.splashDetails) {
        vm.defaultFbLikeUrl = (vm.params.splashDetails.customFbLikeUrl === vm.venue.customFbLikeUrl) ? true : false;
        vm.addlikebutton = (vm.params.splashDetails.customFbLikeUrl) ? true : false;
      } else {
        vm.addlikebutton = false;
        vm.defaultFbLikeUrl = true;
        vm.customFbLike = false;
      }

      var name = type.name;
      switch (name) {
      case 'Video':
        vm.spVideo = true;
        break;
      case 'Social':
        vm.spSocial = true;
        break;
      case 'Email':
        vm.spEmail = true;
        break;
      case 'Link':
        vm.spLink = true;
        break;
      case 'Payment':
        vm.spAmount = true;
        break;
      case 'Register':
        vm.spRegister = true;
        break;
      }
      if (set) {
        for (var splash in vm.params.splashPageTypes) {
          if (vm.params.splashPageTypes[splash].id === type.id) {
            vm.params.splashType = vm.params.splashPageTypes[splash];
          }
        }
      }
    };

    vm.addOpenAuth = function (value) {
      if (vm.params.openAuthList.indexOf(value) > -1) {
        vm.params.openAuthList.splice(vm.params.openAuthList.indexOf(value), 1);
      } else {
        vm.params.openAuthList.push(value);
      }
    };

    vm.selectBckgrndImage = function () {
      vm.params.selectBckgrndImage = true;
      $modalInstance.close(vm.params);
    };

    vm.uploadBckgrndImage = function () {
      vm.params.uploadBckgrndImage = true;
      $modalInstance.close(vm.params);
    };

    vm.uploadLogoImage = function () {
      vm.params.uploadLogoImage = true;
      $modalInstance.close(vm.params);
    };

    vm.selectLogoImage = function () {
      vm.params.selectLogoImage = true;
      $modalInstance.close(vm.params);
    };

    vm.setSplashPageType(vm.params.splashType, true);

    vm.checkChanged = function () {
      vm.params.updateSplash = true;
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };

    vm.ok = function () {
      if (vm.params.splashType.name.toLowerCase() !== 'social') {
        vm.params.openAuthList = [];
      }
      if (vm.params.bckgrndImgAdded && vm.params.splashType.id > 0) {
        if (vm.addlikebutton) {
          if (vm.defaultFbLikeUrl) {
            vm.params.splashDetails.customFbLikeUrl = vm.venue.customFbLikeUrl;
          }
        } else {
            vm.params.splashDetails.customFbLikeUrl = undefined;
        }
        vm.params.updateSplash = true;
        $modalInstance.close(vm.params);
      }
    };
  }
}());
