<div class="container">
  <div class="col-md-5 side-text">
    <div class="mgn-btm-20">
      <img alt="Brand" src="./images/connect2fi/connect2filogo_small.png">
    </div>
    <form name="form" ng-submit="home.login()" role="form">
      <div class="form-group">
        <label for="username" class="control-label pull-left">LOGIN</label>
        <input type="text" class="form-control" id="username" placeholder="Username" ng-model="home.loginDetails.username">
      </div>
      <div class="form-group">
        <label for="password" class="control-label pull-left">PASSWORD</label>
        <input type="password" class="form-control" id="password" placeholder="Credentials" ng-model="home.loginDetails.password">
      </div>
      <div class="form-group" ng-hide="true">
        <label class="checkbox-inline pull-left">
          <input type="checkbox"> Keep me signed in
        </label>
        <label class="checkbox-inline pull-right modal-link">
          <a href="">Forgot Password?</a>
        </label>
      </div>
      <button type="submit" class="btn btn-danger btn-small pull-right">Login</button>
    </form>
    <br />
    <br />
  </div>
  <div class="col-md-7">
    <img src="./images/connect2fi/home-graphic.png" alt="liquidedge phone graphic" class="img-responsive" />
  </div>
</div>