(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name tags.controller:TagsCtrl
   *
   * @description
   *
   */
  angular
    .module('tags')
    .controller('TagsCtrl', TagsCtrl);

  function TagsCtrl(Tags, $modal, $q, $stateParams, $state, Api, Cloudservices) {

    var vm = this;
    vm.venueid = $stateParams.venueid;

    vm.chartColors = ['#b71c1c', '#1a237e', '#f57f17', '#004d40', '#212121', '#880e4f', '#bf360c', '#1b5e20', '#263238', '#4a148c', '#0277bd', '#3e2723', '#558b2f', '#616161', '#311b92', '#ffd740', '#c6ff00', '#607d8b', '#d50000', '#0288d1', '#ffca28', '#795548', '#cddc39', '#757575', '#9c27b0', '#26c6da', '#ff9100', '#d4e157', '#7c4dff'];

    vm.aps = [];
    vm.zones = [];
    vm.users = [];
    vm.tags = [];
    vm.selectedList = '';
    vm.socialtags = [];
    vm.selectFrom = [];
    vm.include = [];
    vm.exclude = [];
    vm.emailList = [];
    vm.mailChimpList = [];
    vm.apiKey = '';
    vm.gotemail = false;
    vm.barChart = {
      data: [],
      series: [],
      labels: [],
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: []
        }
      }
    };

    var getTags = function () {
      vm.barChart.series = [];
      vm.barChart.data = [];
      Tags.getTags(vm.venueid).then(function (result) {
        console.log(result);
        vm.tags = result.data.tags.slice();
        vm.selectFrom = result.data.tags.slice();
        if (vm.tags.length > 0) {
          var length = vm.tags.length;
          for (var i = 0; i < length; i++) {
            vm.barChart.series.push(vm.tags[i].name);
            vm.barChart.data.push([vm.tags[i].users.length]);
          }
        }
        if (vm.barChart.data.length <= 1) {
          vm.barChart.options.scales.xAxes.push({
            barPercentage: 0.3
          });
        } else {
          vm.barChart.options.scales.xAxes = [];
        }
      });
    };

    var getAccessPoints = function () {
      Api.getAccessPoints(vm.venueid).then(function (result) {
        console.log(result);
        vm.aps = result.data.apoints;
      });
    };

    var getZones = function () {
      Api.getZones(vm.venueid).then(function (result) {
        console.log(result);
        vm.zones = result.data.zones;
        console.log(vm.zones);
      });
    };

    var createTag = function (tag) {
      var thisTag = tag;
      Tags.createTag(thisTag).then(function (result) {
        console.log(result);
        getTags();
      });
    };

    var getMailChimpLists = function () {
      Cloudservices.getCloudServices(vm.venueid).then(function (result) {
        var services = result.data.cloudServices;
        var length = services.length;
        var apiKey = '';
        console.log(services);
        for (var i = 0; i < length; i++) {
          if (services[i].type === 'MailChimp') {
            apiKey = services[i].apikey;
            vm.apiKey = services[i].apikey;
            console.log(vm.apiKey);
            break;
          }
        }
        return Api.getLists(apiKey);
      }).then(function (result) {
        vm.mailChimpList = [];
        vm.mailChimpList = result.data.lists;
      });
    };

    vm.createTag = function () {

      var modalInstance = $modal.open({
        templateUrl: 'tags/views/createtag.tpl.html',
        controller: 'TagmodalCtrl as tagmodal',
        resolve: {
          'data': {
            'zones': vm.zones,
            'aps': vm.aps
          }
        }
      });

      modalInstance.result.then(function (result) {
        var tag = result;
        tag.venueId = vm.venueid;
        createTag(tag);
      }, function () {
        console.log('canceled');
      });
    };

    vm.deleteTag = function (tagid) {
      Tags.deleteTag(tagid).then(function (result) {
        console.log(result);
        getTags();
      });
    };

    var updateTag = function (tagid, tag) {
      Tags.updateTag(tagid, tag).then(function (result) {
        console.log(result);
        getTags();
      });
    };

    vm.venueanalytics = function (page) {
      $state.go('admin.newvenueanalytics', {
        'venueid': vm.venueid,
        'class': page
      });
    };

    vm.generateEmailList = function () {
      var body = {
        'include': [],
        'exclude': []
      };

      for (var i = 0; i < vm.include.length; i++) {
        body.include.push(vm.include[i]._id);
      }

      for (var j = 0; j < vm.exclude.length; j++) {
        body.exclude.push(vm.exclude[j]._id);
      }

      Tags.getEmailList(vm.venueid, body).then(function (result) {
        console.log(result.data);
        vm.emailList = result.data.slice();
        vm.gotemail = true;
      });
    };

    var testEmailList = function () {
      Tags.getMocks().then(function (result) {
        vm.emailList = result.data.slice();
        vm.gotemail = true;
      });
    };

    vm.updateTag = function (tagid) {
      var modalInstance = $modal.open({
        templateUrl: 'tags/views/tagupdate.tpl.html',
        controller: 'TagupdateCtrl as tagupdatemodal',
        resolve: {
          'data': {
            'tagid': tagid,
            'venueid': vm.venueid
          }
        }
      });

      modalInstance.result.then(function (result) {
        var tag = result.tag;
        var tagid = result.tagid;
        updateTag(tagid, tag);
      }, function () {
        console.log('canceled');
      });

    };

    vm.sendToList = function () {
      var length = vm.emailList.length;
      var body = {
        'list':{
          'operations': []
        }
      };
      for (var i = 0; i < length; i++) {
        var object = {
          'method': 'POST',
          'path': 'lists/' + vm.selectedList + '/members',
          'body': JSON.stringify({'email_address':vm.emailList[i].login,
          status: 'subscribed'})
        };
        body.list.operations.push(object);
      }
      console.log(body);
      Tags.bulkMailChimp(vm.selectedList, vm.apiKey, body).then(function (response) {
        console.log(response);
      });
    };

    vm.resetEmail = function () {
      vm.selectFrom = vm.tags.slice();
      vm.include = [];
      vm.exclude = [];
      vm.gotemail = false;
    };

    getTags();
    getAccessPoints();
    getZones();
    getMailChimpLists();
    // testEmailList();
  }
}());
