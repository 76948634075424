(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name duplicateevent.controller:DuplicateeventCtrl
   *
   * @description
   *
   */
  angular
    .module('duplicateevent')
    .controller('DuplicateeventCtrl', DuplicateeventCtrl);

  function DuplicateeventCtrl($rootScope, $state, $stateParams, Api, Upload, baseurl, baseendpoint, $timeout, $modal, $localStorage, $log, Auth, Cloudservices) {
    var vm = this;
    vm.ctrlName = 'DuplicateeventCtrl';
    // redirect to appropriate start page
    if ($state.current.name === 'admin.duplicateevent') {
      $state.go('admin.duplicateevent.duplicatesetup');
    }

    vm.ssidCount = false;
    vm.ssidCountAP = [];
    vm.ssidExists = false;
    vm.nameCheckAP = [];
    vm.networkTypes = [];
    vm.zones = [];
    vm.splashPageTypes = [];
    vm.splashPageTypes = [];
    vm.splashType = 0;
    vm.spVideo = false;
    vm.spSocial = false;
    vm.spAmount = false;
    vm.spEmail = false;
    vm.spLink = false;
    vm.networktypesLookup = [];
    vm.categories = [];
    vm.defaultFbLikeUrl = true;
    vm.addlikebutton = false;
    vm.venue = {};
    vm.netPassed = false;
    vm.setupPassed = false;
    vm.splashPassed = false;
    vm.hasTwilio = false;


    // Important!!! remove this sesction once magnet county feature is complete

    vm.isMagnet = false;
    var magnetID = 9104885; //add real magnetID

    if (Auth.getAuthData().operator.organisation === magnetID) {
      vm.isMagnet = true;
    }

    Cloudservices.getCloudServiceByName($stateParams.venueid, 'twilio').then(function (resp) {
      if(resp.data.rowCount >= 1){
        vm.hasTwilio = true;
      }
    }, function (err) {
      console.log(err);
    });

    // handles navigation away from incomplete form
    vm.allowStateChange = false;
    $rootScope.$on('$stateChangeStart',
      function (event, toState, toParams, fromState) {
        console.log(toState);
        console.log(vm.allowStateChange);
        if (vm.allowStateChange === true) {
          return;
        }
        if (fromState.name === 'admin.duplicateevent.confirm') {
          if (toState.name === 'admin.duplicateevent.duplicatesetup' || toState.name === 'admin.duplicateevent.duplicatenetwork' || toState.name === 'admin.duplicateevent.duplicatesplash') {
            vm.allowStateChange = true;
            $state.go(toState.name);
          }
        }
        if (fromState.name === 'admin.duplicateevent.duplicatesetup' || fromState.name === 'admin.duplicateevent.duplicatenetwork' || fromState.name === 'admin.duplicateevent.duplicatesplash' || fromState.name === 'admin.duplicateevent.confirm') {
          if (vm.allowStateChange === false) {
            console.log('called');
            event.preventDefault();
            var modalMessage = {
              ok: 'Ok',
              cancel: 'Don\'t Cancel',
              messageHeader: 'Cancel creating event?',
            };

            var modalInstance = $modal.open({
              templateUrl: 'modalservice/views/modalservice.tpl.html',
              controller: 'ModalserviceCtrl as modalService',
              resolve: {
                message: function () {
                  return modalMessage;
                }
              }
            });

            modalInstance.result.then(function () {
              vm.allowStateChange = true;
              $state.go(toState.name);
            }, function () {

            });
          } else {
            vm.allowStateChange = false;
            return;
          }
        } else {
          vm.allowStateChange = false;
          return;
        }
        vm.allowStateChange = false;
      });
    // end state changes

    vm.storage = $localStorage;

    //get network types
    function getNetworkTypes(venueid) {
      Api.getNetworkTypes(venueid).then(function (response) {
        $log.info(response.data.networkTypes);
        vm.networkTypes = response.data.networkTypes;
        for (var i = 0; i < vm.networkTypes.length; i++) {
          vm.networktypesLookup[vm.networkTypes[i].id] = vm.networkTypes[i];
        }
        if (vm.event.networktype) {
          vm.networkType = vm.networktypesLookup[vm.event.networktype];
        }
      }, function (err) {
        $log.info(err);
      });
    }

    //get splash page types
    function getSplashPageTypes() {
      Api.getSplashPageTypes(vm.provider).success(function (data) {
        vm.splashPageTypes = data.splashTypes;
        getSocialLogins();
        getCategories();
        $log.info(vm.splashPageTypes);
        vm.setSplashPageType(vm.splashType, true);
      }).error(function () {
        $log.info('get splash page types error');
      });
    }

    function getSocialLogins() {
      Api.getSocialLogins(vm.provider).success(function (data) {
        for (var i = 0; i < data.socialLoginTypes.length; i++) {
          if(data.socialLoginTypes[i].name.toLowerCase() === 'facebook') {
            data.socialLoginTypes.splice(i, 1);
            i= (i-1 > 0)?i-1:i ;
          }
          else if(data.socialLoginTypes[i].name.toLowerCase() === 'anonymous') {
            vm.anonymousLogin = data.socialLoginTypes[i];
            vm.anonymousLogin.on = false;
            data.socialLoginTypes.splice(i, 1);
            i=(i-1 > 0)?i-1:i;
          }
        }
        vm.socialLogins = data.socialLoginTypes;

        $log.info('social');
        $log.info(vm.socialLogins);
      }).error(function () {
        $log.info('get social error');
      });
    }

    // categories setup
    function getCategories() {
      Api.getCategories(vm.provider).success(function (data) {
        vm.optCatList = data.categories;
        $log.info(vm.optCatList);
      }).error(function () {
        $log.info('get categories error');
      });
    }
    // end categories setup

    // manage social media

    vm.openAuthList = [];

    vm.addOpenAuth = function (value) {
      if (vm.openAuthList.indexOf(value) > -1) {
        vm.openAuthList.splice(vm.openAuthList.indexOf(value), 1);
      } else {
        vm.openAuthList.push(value);
      }
    };

    vm.priceSlider = {
      value: 2,
      options: {
        floor: 0,
        ceil: 50,
        translate: function (value) {
          return value + ' MB';
        }
      }
    };

    //set Splash Page Type
    vm.setSplashPageType = function (type, set) {
      console.log(type);
      console.log(set);
      vm.spVideo = false;
      vm.spSocial = false;
      vm.spAmount = false;
      vm.spEmail = false;
      vm.spLink = false;
      var name = type.name;
      switch (name) {
      case 'Video':
        vm.spVideo = true;
        break;
      case 'Social':
        vm.spSocial = true;
        break;
      case 'Email':
        vm.spEmail = true;
        break;
      case 'Link':
        vm.spLink = true;
        break;
      case 'Payment':
        vm.spAmount = true;
        break;
      }
      if (set) {
        for (var splash in vm.splashPageTypes) {
          if (vm.splashPageTypes[splash].id === type.id) {
            vm.splashType = vm.splashPageTypes[splash];
          }
        }
        console.log(vm.splashType);
      }
    };

    // Time And Date
    vm.today = function () {
      var newDate = new Date();
      vm.dtStart = new Date(newDate);
      vm.dtStart.setMinutes(newDate.getMinutes() + 10);
      vm.dtEnd = new Date(vm.dtStart);
      vm.dtEnd.setDate(vm.dtStart.getDate() + 1);
      vm.minDate = newDate;
    };

    vm.today();

    // manage steps

    vm.eventTime = function () {
      vm.event.starttime = new Date(vm.event.starttime);
      vm.event.starttime.setHours(vm.event.starttime.getHours());
      vm.event.starttime.setMinutes(vm.event.starttime.getMinutes());
      vm.event.starttime.setSeconds(0);

      vm.event.endtime = new Date(vm.event.endtime);
      vm.event.endtime.setHours(vm.event.endtime.getHours());
      vm.event.endtime.setMinutes(vm.event.endtime.getMinutes());
      vm.event.endtime.setSeconds(0);
    };

    vm.nextStep = function (data, state) {
      if ($state.current.name === 'admin.duplicateevent.duplicatesetup') {
        var timeError = false,
          now = new Date(),
          startTime = new Date(vm.event.startDate),
          endTime = new Date(vm.event.endDate),
          hour = 3600000,
          modalInstance;
        vm.stepOne = true;
        vm.stTimeInPast = false;
        vm.endTimeInPast = false;
        vm.endBeforeStart = false;
        vm.dateError = false;

        vm.eventTime();

        if (!vm.event.starttime || !vm.event.endtime) {
          timeError = true;
          vm.dateError = true;
        }

        if (endTime.getTime() < now.getTime()) {
          timeError = true;
          vm.endTimeInPast = true;
        }

        if (endTime.getTime() < startTime.getTime()) {
          timeError = true;
          vm.endBeforeStart = true;
        }

        if (!data.setUp.required && vm.eventImageAdded && !timeError) {
          if (endTime.getTime() - startTime.getTime() < hour) {
            modalInstance = $modal.open({
              templateUrl: 'eventcreation/views/eventtimemodal.tpl.html',
              controller: 'CancelmodalCtrl as cancelModal'
            });

            modalInstance.result.then(function () {
              vm.setupPassed = true;
              vm.allowStateChange = true;
              $state.go(state);
            }, function () {
              console.log('canceled');
            });
          } else {
            vm.allowStateChange = true;
            vm.setupPassed = true;
            $state.go(state);
          }
        }
      }

      if ($state.current.name === 'admin.duplicateevent.duplicatenetwork') {
        vm.stepTwo = true;
        var newZoneList = [];
        for (var i = 0; i < vm.zones.length; i++) {
          newZoneList.push(vm.zones[i].id);
        }
        var thisList = {
          zones: newZoneList,
          ssidname: vm.event.ssidname
        };

        Api.checkSSIDName(vm.event.starttime.getTime(), vm.event.endtime.getTime(), thisList).then(function (resp) {
          console.log(resp.status);
          Api.checkEventCount(vm.event.starttime.getTime(), vm.event.endtime.getTime(), thisList).then(function () {
            if (!data.network.required && !data.network.pattern && vm.zones.length >= 1) {
              vm.netPassed = true;
              vm.allowStateChange = true;
              $state.go(state);
            }
          }, function (err) {
            vm.ssidCount = true;
            vm.ssidCountAP = err.data.accesspoints;
          });
        }, function (err) {
          vm.ssidExists = true;
          vm.nameCheckAP = err.data.accesspoints;
          Api.checkEventCount(vm.event.starttime.getTime(), vm.event.endtime.getTime(), thisList).then(function () {

          }, function (err) {
            vm.ssidCount = true;
            vm.ssidCountAP = err.data.accesspoints;
          });
        });
      }

      if ($state.current.name === 'admin.duplicateevent.duplicatesplash') {
        vm.stepThree = true;
        var thisSplashType = vm.splashType.name;

        if (!data.splash.required && vm.bckgrndImgAdded && vm.splashType.id > 0) {
          if (thisSplashType === 'Social' && vm.openAuthList.length >= 1) {
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else if (thisSplashType === 'Video' && vm.event.splashDetails.videoUrl.length > 0) {
            console.log('called');
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else if (thisSplashType === 'Payment' && vm.event.splashDetails.amount > 0 && vm.event.splashDetails.amount > 0) {
            console.log('called');
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          } else {
            vm.splashPassed = true;
            vm.allowStateChange = true;
            $state.go(state);
          }
        }
      }
      vm.allowStateChange = false;
    };

    // manage event date/time

    vm.minDate = new Date();

    vm.dateOptions = {
      formatYear: 'yy',
      startingDay: 0
    };

    vm.dateFormat = 'dd-MMMM-yyyy';
    vm.hstep = 1;
    vm.mstep = 1;

    vm.status = {
      openedSt: false,
      openedEnd: false
    };

    vm.openCalSt = function () {
      vm.status.openedSt = true;
    };

    vm.openCalEnd = function () {
      vm.status.openedEnd = true;
    };

    // get images
    Api.getImages().success(function (data) {
      // multiple image lists required
      vm.eventImageList = data.images;
      for (var i = 0; i < vm.eventImageList.length; i++) {
        vm.eventImageList[i].checked = false;
      }
      vm.venueImageList = angular.copy(vm.eventImageList);
      vm.backgroundImageList = angular.copy(vm.eventImageList);
      $log.info(vm.eventImageList);
      $log.info(vm.venueImageList);
      $log.info(vm.backgroundImageList);
    });

    vm.uploadEventImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal'
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.eventImageAdded = true;
        vm.eventImage = image.imageUri;
        vm.eventImageID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectEventImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.eventImageAdded = true;
        vm.eventImage = thisImage.imageuri;
        vm.eventImageID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    vm.uploadLogoImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.venueLogoAdded = true;
        vm.venueLogo = image.imageUri;
        vm.venueLogoID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectLogoImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.venueLogoAdded = true;
        vm.venueLogo = thisImage.imageuri;
        vm.venueLogoID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    vm.uploadBckgrndImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        console.log(image);
        vm.bckgrndImgAdded = true;
        vm.bckgrndImg = image.imageUri;
        vm.bckgrndImgID = image.id;
        console.log('success');
      }, function () {
        console.log('canceled');
      });
    };

    vm.selectBckgrndImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'selectimage/views/selectimage.tpl.html',
        controller: 'SelectimageCtrl as selectimage',
        size: 'lg',
        resolve: {
          'venue': vm.event.venue
        }
      });

      modalInstance.result.then(function (image) {
        var thisImage = angular.fromJson(image);
        vm.bckgrndImgAdded = true;
        vm.bckgrndImg = thisImage.imageuri;
        vm.bckgrndImgID = thisImage.id;
      }, function () {
        console.log('canceled');
      });
    };

    vm.nameCheckResult = function () {
      var modalMessage = {
        messageHeader: 'Network Name Error',
        messageBody: 'Only one instance of a network name can run in a given time period, the access points below have already been assigned "' + vm.event.ssidname + '" for the given time period',
        accesspoints: vm.nameCheckAP
      };

      var modalInstance = $modal.open({
        templateUrl: 'duplicateevent/views/duplicatessidchecks.tpl.html',
        controller: 'DuplicatessidchecksCtrl as modalService',
        resolve: {
          message: function () {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function () {});

    };

    vm.ssidCountResult = function () {
      var modalMessage = {
        messageHeader: 'Network Limit Error',
        messageBody: 'A maximum of seven event can run simultainiously, the access points below will exceed this limit and cannot be created, remove them to cntinue',
        accesspoints: vm.ssidCountAP
      };

      var modalInstance = $modal.open({
        templateUrl: 'duplicateevent/views/duplicatessidchecks.tpl.html',
        controller: 'DuplicatessidchecksCtrl as modalService',
        resolve: {
          message: function () {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function () {});

    };

    Api.getEvent($stateParams.eventid, $stateParams.venueid).success(function (data) {
      vm.event = data.event;
      vm.zones = data.event.zones;
      console.log('event');
      console.log(vm.event);
      console.log('slider value' + vm.event.trafficlimitsta / 1000);
      vm.getZones(vm.event.venue);
      vm.splashType = vm.event.splashtypeid;
      vm.priceSlider.value = vm.event.trafficlimitsta / 1000;
      var thisSplash;
      if (vm.event.splashdetails) {
        thisSplash = vm.event.splashdetails;
        if (thisSplash.amount) {
          thisSplash.amount = thisSplash.amount / 100;
        }
        if (thisSplash.period) {
          thisSplash.period = thisSplash.period / 3600000;
        }
      }
      var splashType = {};
      splashType.id = vm.event.splashtypeid;
      splashType.name = vm.event.splashtypename;
      vm.splashType = splashType;
      vm.event.networkType = vm.event.networktype;
      //vm.event.splashDetails.videoUrl = vm.event.splashdetails.videoURL;
      if (vm.event.eventimageurl.length > 0) {
        vm.eventImageAdded = true;
        vm.eventImageID = vm.event.eventimage;
        vm.eventImage = vm.event.eventimageurl;
      }
      if (vm.event.backgroundimage.length > 0) {
        vm.bckgrndImgAdded = true;
        vm.bckgrndImgID = vm.event.splashpagebackgroundimage;
        vm.bckgrndImg = vm.event.backgroundimage;
      }
      if (vm.event.logoimage.length > 0) {
        vm.venueLogoAdded = true;
        vm.venueLogoID = vm.event.splashpagelogoimage;
        vm.venueLogo = vm.event.logoimage;
      }
      for (var i = 0; i < vm.event.socialLoginTypes.length; i++) {
        vm.openAuthList.push(vm.event.socialLoginTypes[i].id);
      }
      for (var j = 0; j < vm.event.categories.length; j++) {
        vm.categories.push(vm.event.categories[j].id);
      }
      if (Auth.getAuthData()) {
        vm.event.org = Auth.getAuthData().operator.organisation;
      }
      vm.event.starttime = vm.dtStart;
      vm.event.endtime = vm.dtEnd;
      Api.getVenue(vm.event.venue).success(function (data) {
        var detailsJson = {};
        if(typeof data.venue.details === 'string'){
          detailsJson = JSON.parse(data.venue.details);
        } else {
          detailsJson = data.venue.details;
        }
        if(detailsJson){
          vm.venue.customFbLikeUrl = detailsJson.fbpage;
          if (vm.venue.customFbLikeUrl) {
            vm.defaultFbLikeUrl = true;
            if (vm.event.splashdetails) {
              if (vm.event.splashdetails.customFbLikeUrl) {
                vm.customFbLike = true;
                if (vm.event.splashdetails.customFbLikeUrl !== vm.venue.customFbLikeUrl) {
                  vm.defaultFbLikeUrl = false;
                }
              } else {
                vm.customFbLike = false;
              }
            }
          } else {
            vm.customFbLike = true;
            vm.defaultFbLikeUrl = false;
          }
        } else {
          vm.venue.customFbLikeUrl = null;
          vm.customFbLike = true;
        }
      });
      if (vm.event.splashdetails) {
        vm.addlikebutton = (vm.event.splashdetails.customFbLikeUrl) ? true : false;
      } else {
        vm.addlikebutton = false;
        vm.defaultFbLikeUrl = true;
        vm.customFbLike = false;
      }
      Api.getInfrastructures(vm.event.venue).then(function (res) {
        var infra = res.data.infrastructures[0];
        vm.provider = JSON.parse(infra.provider);
        getSplashPageTypes();
      }, function (err) {
        console.log(err);
      });
    });

    //accesspoint setup
    vm.getZones = function (id) {
      vm.zoneList = [];
      Api.getZones(id).success(function (data) {
        vm.zoneList = data.zones;
        var newList = [];
        for (var i = 0; i < vm.zoneList.length; i++) {
          for (var j = 0; j < vm.event.zones.length; j++) {
            if (vm.zoneList[i].id === vm.event.zones[j].zone) {
              newList.push(vm.zoneList[i]);
            }
          }
        }
        vm.event.zones = newList;
      }).error(function () {
        $log.info('get ap error');
      });
      getNetworkTypes(id);
    };

    vm.setNetworkType = function (typeid) {
      vm.event.networktype = typeid;
      vm.event.optCatList = [];
      Api.getNetworkType(typeid, vm.event.venue).then(function (response) {
        $log.info(response);
        if (response.data.networkType.networkStrategies.length > 0) {
          console.log(response.data.networkType.networkStrategies.length);
          for (var i = 0; i < response.data.networkType.networkStrategies.length; i++) {
            vm.event.optCatList.push(response.data.networkType.networkStrategies[i].id);
          }
          //vm.categories = vm.event.optCatList;
        }
      }, function (err) {
        $log.info(err);
      });
    };

    vm.cancel = function () {
      $state.go('admin.events');
    };

    vm.updateEvent = function () {
      vm.eventTime();
      var thisSplash = vm.event.splashdetails;
      if (thisSplash) {
        if (thisSplash.amount) {
          thisSplash.amount = thisSplash.amount * 100;
        }
        if (thisSplash.period) {
          thisSplash.period = thisSplash.period * 3600000;
        }
      }
      if (vm.addlikebutton) {
        if (vm.defaultFbLikeUrl) {
          vm.event.splashdetails.customFbLikeUrl = vm.venue.customFbLikeUrl;
        }
      } else {
          vm.event.splashdetails.customFbLikeUrl = undefined;
      }
      // if(vm.defaultFbLikeUrl){
      //   vm.event.splashdetails.customFbLikeUrl = vm.defaultFbPage;
      // } else if (!vm.addlikebutton) {
      //   vm.event.splashdetails.customFbLikeUrl = undefined;
      // }
      var thisBandwidth = vm.priceSlider.value * 1000,
        thisEvent = {
          orgId: vm.event.org,
          venueId: vm.event.venue,
          name: vm.event.name,
          description: vm.event.description,
          eventimage: vm.eventImageID,
          ssidname: vm.event.ssidname,
          starttime: vm.event.starttime,
          endtime: vm.event.endtime,
          zones: vm.zones,
          categories: vm.event.categories,
          trafficlimitsta: thisBandwidth,
          splashpagebackgroundimage: vm.bckgrndImgID,
          splashpagelogoimage: vm.venueLogoID,
          sociallogintypes: vm.openAuthList,
          splashTypeId: vm.splashType.id,
          splashDetails: thisSplash,
          redirectionurl: vm.event.redirectionurl,
          networkTypeId: vm.event.networkType,
          eventstatus: vm.event.eventstatus
        };

      if (vm.event.description === null) {
        thisEvent.description = '';
      }
      if (vm.splashType.name.toLowerCase() !== 'social') {
        thisEvent.sociallogintypes = [];
      }
      var catList = [];
      for (var j = 0; j < vm.event.categories.length; j++) {
        catList.push(vm.event.categories[j].id);
      }

      Api.createEvent(thisEvent, vm.event.venue).success(function () {
        vm.storage.alerts.push({
          messageType: 'event',
          message: 'Event Update'
        });
        vm.allowStateChange = true;
        $state.go('admin.events');
      }).error(function (error) {
        console.log(error);
      });
    };
  }
}());
